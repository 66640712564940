var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$store.state.emp.cod_empresa == 381)?_c('CompraSwisstex'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 383)?_c('CompraConab'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 469)?_c('CompraIBM'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 445)?_c('CompraTX'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 372)?_c('CompraSperidian'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 460)?_c('ComprasHYGGE'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 380)?_c('ComprasUnique'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 466)?_c('CompraNaka'):_vm._e(),(
    _vm.$store.state.emp.cod_empresa != 372 &&
    _vm.$store.state.emp.cod_empresa != 381 &&
    _vm.$store.state.emp.cod_empresa != 383 &&
    _vm.$store.state.emp.cod_empresa != 469 &&
    _vm.$store.state.emp.cod_empresa != 445 &&
    _vm.$store.state.emp.cod_empresa != 460 &&
    _vm.$store.state.emp.cod_empresa != 380 &&
    _vm.$store.state.emp.cod_empresa != 466
  )?_c('div',[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":350,"width":350},on:{"animCreated":_vm.handleAnimation}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }