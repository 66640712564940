<template>
    <v-container class="pa-4">

        <v-row class="mb-6" justify="center">
            <v-col cols="4" md="4" lg="4" class="pa-4">
                <v-text-field v-model="anio" label="Año" outlined dense hide-details></v-text-field>
            </v-col>

            <v-col cols="4" md="4" lg="4" class="pa-4">
                <v-autocomplete v-model="mes" :items="items_mes" item-text="mes" item-value="id_mes" label="Mes"
                    outlined dense hide-details>
                </v-autocomplete>
            </v-col>

            <v-col cols="4" md="4" lg="5" class="pa-4">
                <v-autocomplete v-model="sucursal" :items="items_sucursales" item-text="nombre" item-value="id_sucursal"
                    label="Sucursal" outlined dense hide-details>
                    <template v-slot:selection="data">
                        {{ data.item.cod_sucursal }} {{ data.item.nombre }}
                    </template>
                    <template v-slot:item="data">
                        {{ data.item.cod_sucursal }} {{ data.item.nombre }}
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>


        <v-row justify="center" class="button-row">
            <v-col cols="auto" class="text-center">
                <v-btn class="button-custom" elevation="6" fab color="cyan" @click="descargarFACTURA">
                    <img src="@/assets/factura.png" alt="Factura" class="button-image" />
                </v-btn>
                <div class="button-label text-gradient">Facturas</div>
            </v-col>
            <v-col cols="auto" class="text-center">
                <v-btn class="button-custom" elevation="6" fab color="cyan" @click="descargarCompras">
                    <img src="@/assets/doc_com.png" alt="Compras" class="button-image" />
                </v-btn>
                <div class="button-label text-gradient">Compras</div>
            </v-col>
        </v-row>

 
        <v-dialog v-model="dialog_preview_report" fullscreen persistent>
            <v-toolbar flat dense>
                <v-toolbar-title>Documento</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon @click="dialog_preview_report = false">far fa-times-circle</v-icon>
            </v-toolbar>
            <iframe :src="base64_doc" id="myPDF" frameborder="0"></iframe>
        </v-dialog>


        <v-snackbar v-model="snackbar" timeout="4000" color="blue" multi-line elevation="4" outlined top right>
            <v-icon>fa-solid fa-circle-info</v-icon>
            {{ snackbar_text }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";


export default {
    name: 'CircularButtons',
    data() {
        return {

            anio: "",
            mes: "",
            sucursal: "",

            //Requsitos
            tipoDocumento: "", // A 
            documento_identificador: "", // B 
            nombre: "", // C 
            fecha: "",  // D
            serie: "", // E 
            documento: "", // F 
            internas_importaciones: "", // G 
            iva: "", // H 
            tipo_operacion: "", // I 
            clasificacion: "",  // J 
            rubro: "", // K 
            costo_gastos: "", // L 
            numeroAnexo: "5", // M 

            dialog_preview_report: false,
            base64_doc: "",

            loading_compras: false,
            items_compras: [],
            items_sucursales: [],
            items_mes: [],
            items_fac: [],

            snackbar: false,
            snackbar_text: ""
        }
    },

    created() {
        this.listarSucursales();
        this.listarMes();
        this.listarProveedores();
        this.listarClientes();
        this.generarReporte();
    },

    methods: {


        getProveedorByIdLocal(id_proveedor) {
            if (this.items_proveedores && this.items_proveedores[id_proveedor]) {
                return this.items_proveedores[id_proveedor];
            }
            return null;
        },

        getClienteByIdLocal(id_cliente) {
            if (this.items_clientes && this.items_clientes[id_cliente]) {
                return this.items_clientes[id_cliente];
            }
            return null;
        },


        listarFacturas() {
            this.loading_facturas = true;
            axios
                .get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa)
                .then((response) => {
                    this.items = response.data;
                    this.loading_facturas = false;
                })
                .catch(() => {
                    this.loading_facturas = false;
                });
        },


        listarSucursales() {
            axios
                .get("api/Sucursales/Listar/" + this.$store.state.emp.id_empresa)
                .then((response) => {
                    this.items_sucursales = response.data;
                    this.sucursal = response.data[0].id_sucursal;
                });
        },

        listarMes() {
            axios.get("api/Mes/Listar")
                .then((response) => {
                    this.items_mes = response.data;
                });
        },

        // Listar todos los proveedores 
        listarProveedores() {
            return axios
                .get("api/Proveedores/Listar/" + this.$store.state.emp.id_empresa)
                .then((response) => {
                    this.items_proveedores = response.data.reduce((map, proveedor) => {
                        map[proveedor.id_proveedor] = proveedor;
                        return map;
                    }, {});


                })
                .catch((error) => {
                    console.error("Error al listar los proveedores:", error);
                    this.snackbar_text = "No se pudieron cargar los proveedores";
                    this.snackbar = true;
                });
        },

        listarClientes() {
            return axios
                .get("api/Clientes/ListarTodos/" + this.$store.state.emp.id_empresa)
                .then((response) => {
                    this.items_clientes = response.data.reduce((map, cliente) => {
                        map[cliente.id_cliente] = cliente;
                        return map;
                    }, {});


                })
                .catch((error) => {
                    console.error("Error al listar los clientes:", error);
                    this.snackbar_text = "No se pudieron cargar los clientes";
                    this.snackbar = true;
                });
        },


        descargarFACTURA() {
            if (this.$store.state.emp.id_empresa) {
                this.loading_facturas = true;

                // Obtener facturas y clientes
                const facturasPromise = axios.get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa);
                const clientesPromise = axios.get("api/Clientes/Listar/" + this.$store.state.emp.id_empresa);

                Promise.all([facturasPromise, clientesPromise])
                    .then(([facturasResponse, clientesResponse]) => {
                        const facturasData = facturasResponse.data;


                        const id_factura = facturasData
                            .filter(item => {
                                const fecha = new Date(item.fecha);
                                const itemAnio = fecha.getFullYear();
                                const itemMes = fecha.getMonth() + 1;

                                return itemAnio === parseInt(this.anio) && itemMes === parseInt(this.mes);
                            })
                            .map(factura => factura.id_factura);

                        if (id_factura.length > 0) {
                            // API
                            const parametros = {
                                id_facturas: id_factura,
                                tipo_doc: "pdf", // DOC
                                server: "LOCAL" // LOCAL o SERVER  

                            };

                            axios.post("api/Facturas/ReporteFactura", parametros)
                                .then(response => {
                                    const reporteURL = response.data;

                                    const csvContent = this.generarCSVFACTURA(facturasData);
                                    const link = document.createElement('a');
                                    link.href = reporteURL;
                                    link.setAttribute('download', `Reporte_FAC_${this.anio}_${this.mes}.pdf`);
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);

                                    this.base64_doc = "data:application/pdf;base64," + response.data;
                                    this.dialog_preview_report = true;

                                    this.snackbar_text = "Reporte generado exitosamente";
                                    this.snackbar = true;
                                })
                                .catch(error => {
                                    /*console.error("Error al generar el reporte FAC:", error);
                                    this.snackbar_text = "No se pudo generar el reporte FAC";
                                    this.snackbar = true;*/
                                })
                                .finally(() => {
                                    this.loading_facturas = false;
                                });
                        } else {
                            //console.warn("No hay facturas disponibles para el reporte");
                            this.snackbar_text = "No hay documentos FAC para el año y mes seleccionados";
                            this.snackbar = true;
                            this.loading_facturas = false;
                        }
                    })
                    .catch(error => {
                        /*console.error("Error al obtener facturas o clientes:", error);
                        this.snackbar_text = "No se pudo obtener información de facturas o clientes";
                        this.snackbar = true;
                        this.loading_facturas = false;*/
                    });
            } else {
                /*console.error("El id_empresa es undefined");
                this.snackbar_text = "El id_empresa no está definido";
                this.snackbar = true;*/
            }
        },

        descargarCompras() {
            if (this.$store.state.emp.id_empresa) {
                this.loading_compras = true;

                axios.get("api/Compras/Listar/" + this.$store.state.emp.id_empresa)
                    .then((response) => {
                        if (Array.isArray(response.data)) {

                            const comprasData = response.data.filter(item => {
                                const fecha = new Date(item.fecha);
                                const itemAnio = fecha.getFullYear();
                                const itemMes = fecha.getMonth() + 1;

                                return itemAnio === parseInt(this.anio) && itemMes === parseInt(this.mes);
                            }).map(compra => {

                                const proveedor = this.getProveedorByIdLocal(compra.id_proveedor);
                                if (proveedor) {
                                    compra.nit_ncr = proveedor.id_identificador === "1"
                                        ? proveedor.documento_identificador.replace(/-/g, "")
                                        : "";

                                    compra.dui = proveedor.id_identificador = "2"
                                        ? proveedor.documento_identificador.replace(/-/g, "")
                                        : "";

                                    compra.proveedor = proveedor.nombre || "Sin Nombre";
                                } else {
                                    compra.nit_ncr = "N/A";
                                    compra.dui = "N/A";
                                    compra.proveedor = "Proveedor Desconocido";
                                }
                                return compra;
                            });

                            // Obtener los IDs de compras
                            const id_compra = comprasData.map(item => item.id_compra);

                            if (id_compra.length > 0) {
                                // Crear los parámetros para la solicitud
                                const parametros = {
                                    id_compras: id_compra,
                                    tipo_doc: "pdf", // Tipo Doc
                                    server: "LOCAL" // LOCAL o SERVER
                                };


                                axios.post("api/Facturas/ReporteComprasT", parametros)
                                    .then(response => {
                                        const reporteURL = response.data;

                                        // Descargar el archivo generado
                                        const link = document.createElement('a');
                                        link.href = reporteURL;
                                        link.setAttribute('download', `Reporte_Compra_${this.anio}_${this.mes}.pdf`);
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);

                                        this.base64_doc = "data:application/pdf;base64," + response.data;
                                        this.dialog_preview_report = true;

                                        this.snackbar_text = "Reporte generado exitosamente";
                                        this.snackbar = true;
                                    })
                                    .catch(error => {
                                        /*console.error("Error al generar el reporte Compra:", error);
                                        this.snackbar_text = "No se pudo generar el reporte Compra";
                                        this.snackbar = true;*/
                                    })
                                    .finally(() => {
                                        this.loading_facturas = false;
                                    });
                            } else {
                                //console.warn("No hay documentos Compra para el año y mes seleccionados");
                                this.snackbar_text = "No hay Compra para el año y mes seleccionados";
                                this.snackbar = true;
                                this.loading_facturas = false;
                            }
                        } else {
                            /*console.error("Respuesta no esperada:", response.data);
                            this.snackbar_text = "Error en la respuesta del servidor";
                            this.snackbar = true;
                            this.loading_facturas = false;*/
                        }
                    })
                    .catch(error => {
                        /*console.error("Error al descargar los documentos Compra:", error);
                        this.snackbar_text = "No se pudo descargar el archivo Compra";
                        this.snackbar = true;
                        this.loading_facturas = false;*/
                    });
            } else {
                /*console.error("El id_empresa es undefined");
                this.snackbar_text = "El id_empresa no está definido";
                this.snackbar = true;*/
            }
        },



        generarCSVFACTURA(data) {

            const rows = data.map(row => {
                const exportacionesDentro = row.id_tipo_exportacion === "REGION CENTROAMERICANA" ? row.gravadas : "0.00";
                const exportacionesFuera = row.id_tipo_exportacion === "FUERA REGION CENTROAMERICANA" ? row.gravadas : "0.00";
                const gravadasLocales = row.id_tipo_exportacion ? "0.00" : row.gravadas;
                const zonaFranca = row.es_zona_franca_dpa ? row.gravadas : "0.00";

                return [
                    this.formatDate(row.fecha), // Fecha Emisión
                    "4", // Clase Documento
                    "01", // Tipo Documento
                    "N/A", // Número de Resolución
                    "N/A", // Número Serie
                    "N/A", // Número de Control Interno DEL
                    "N/A", // Número de Control Interno AL
                    "N/A", // Número de Documento DEL
                    "N/A", // Número de Documento AL
                    "", // Número de Maquina Registradora
                    row.exentas || "0.00", // Ventas Exentas
                    row.ventas_internas_exentas || "0.00", // Ventas Internas Exentas
                    row.no_sujetas || "0.00", // Ventas No Sujetas
                    gravadasLocales, // Ventas Gravadas Locales
                    exportacionesDentro, // Exportaciones Dentro de CA
                    exportacionesFuera, // Exportaciones Fuera de CA
                    row.exportacion_servicios || "0.00", // Exportaciones de Servicios
                    zonaFranca, // Ventas a Zonas Francas Y DPA
                    row.venta_terceros || "0.00", // Ventas a Terceros no Domiciliados
                    row.total_pagar || "0.00", // Total Ventas
                    row.tipo_operacion || "4", // Q: Tipo de Operación
                    row.tipoingreso || "1", // R: Tipo de Ingreso
                    "2" // Número de Anexo
                ].join(";");
            });

            return rows.join("\n");
        },

        //Generador del Documento CSV-Compras
        generarCSVCompras(data) {
            const rows = data.map(row => {
                // Cálculos para Exentas + No Sujetas si es extranjero
                const exentasNoSujetas = parseFloat(row.internas_importaciones_exentas || "0") + parseFloat(row.no_sujeto || "0");

                // Valores según área y tipo de proveedor
                let comprasInternasExentas = "0.00";
                let internacionesExentas = "0.00";
                let importacionesExentas = "0.00";

                let comprasInternasGravadas = "0.00";
                let internacionesGravadas = "0.00";
                let importacionesGravadas = "0.00";

                if (row.extranjero) {
                    if (row.area = "REGION LOCAL") {
                        comprasInternasExentas = exentasNoSujetas.toFixed(2);
                    } else if (row.area = "IMPORTACION") {
                        internacionesExentas = exentasNoSujetas.toFixed(2);
                    } else if (row.area = "INTERNACION") {
                        importacionesExentas = exentasNoSujetas.toFixed(2);
                    }
                } else {
                    // Caso de local: usar gravadas
                    if (row.area = "REGION LOCAL") {
                        comprasInternasGravadas = (row.internas_importaciones || "0.00");
                    } else if (row.area = "IMPORTACION") {
                        internacionesGravadas = (row.internas_importaciones || "0.00");
                    } else if (row.area = "INTERNACION") {
                        importacionesGravadas = (row.internas_importaciones || "0.00");
                    }
                }

                // Tipo de Documento (columna C)
                const tipoDocumento = (() => {
                    if (row.id_tipo_doc_fiscal = "CCF") {
                        return "03";
                    } else if (row.id_tipo_doc_fiscal = "NCR") {
                        return "05";
                    } else if (row.id_tipo_doc_fiscal = "NDB") {
                        return "06";
                    }
                })();

                return [
                    this.formatDate(row.fecha), // A: Fecha Emisión (DD/MM/AAAA)
                    "4", // B: Clase de Documento
                    tipoDocumento, // C: Tipo de Documento
                    row.documento ? row.documento.replace(/-/g, "") : "", // D: Número de Documento
                    row.nit_ncr || "", // E: NIT del Proveedor (Solo si id_identificador === "1")
                    row.proveedor || "", // F: Nombre del Proveedor
                    comprasInternasExentas, // G: Compras Internas Exentas/No Sujetas
                    internacionesExentas, // H: Internaciones Exentas/No Sujetas
                    importacionesExentas, // I: Importaciones Exentas/No Sujetas
                    comprasInternasGravadas, // J: Compras Internas Gravadas
                    internacionesGravadas, // K: Internaciones Gravadas de Bienes
                    importacionesGravadas, // L: Importaciones Gravadas de Bienes
                    row.importaciones_gravadas_servicios || "0", // M: Importaciones Gravadas de Servicios
                    row.iva || "0.00", // N: Crédito Fiscal
                    row.total_pagar || "0.00", // O: Total Compras
                    row.dui || "", // P: DUI del Proveedor (Solo si id_identificador === "2")
                    row.tipo_operacion || "4", // Q: Tipo de Operación
                    row.clasificacion || "", // R: Clasificación
                    row.rubro || "", // S: Sector
                    row.costo_gastos || "", // T: Tipo de Costo/Gasto
                    "3" // U: Anexo
                ].join(";");
            });

            return rows.join("\n");
        },


        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${day}/${month}/${year}`;
        },

        generarReporte() {
            axios.get('api/Factura/ReporteFAC')
                .then(response => {
                    this.items_fac = response.data;
                })
                .catch(error => {
                    console.error('Error al generar el reporte:', error);
                });
        },
    }
}
</script>


<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#myPDF {
    width: 100%;
    height: 100%;
}

.button-row {
    gap: 16px;
}

.button-custom {
    width: 200px;
    height: 200px;
    transition: transform 0.3s ease;
}

.button-custom:hover {
    transform: scale(1.1);
}

.button-image {
    width: 150px;
    height: 150px;
}

.button-label {
    margin-top: 8px;
    font-size: 18px;
    font-weight: bold;
    animation: fadeIn 0.5s ease;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.text-gradient {
    background: linear-gradient(90deg, #000000, #333333);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>