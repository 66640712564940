<template>
    <v-container fluid>
      <LibrosConab v-if="$store.state.emp.cod_empresa == 383"/>  
      <LibrosSwisstex v-if="$store.state.emp.cod_empresa == 381"/>  
      <LibrosUnique v-if="$store.state.emp.cod_empresa == 380"/>
      <LibrosPersonalSoft v-if="$store.state.emp.cod_empresa == 398"/>
      <LibrosIBMaker v-if="$store.state.emp.cod_empresa == 469"/>

    </v-container>
  </template>
  
  <script>
  import LibrosConab from './Conab/ConabIVA.vue';
  import LibrosSwisstex from './Swisstex/SwisstexIVA.vue';
  import LibrosUnique from './Uniquee/IVAU.vue';
  import LibrosPersonalSoft from './PersonalSoft/PersonalSoftIVA.vue';
  import LibrosIBMaker from './IBMaker/IBMlibros.vue';
  
  export default {
    data: () => ({
      //
    }),
    components: {
      LibrosConab,
      LibrosSwisstex,
      LibrosUnique,
      LibrosPersonalSoft,
      LibrosIBMaker,
    },
  };
  </script>