<template>

  <v-container class="text-center">
    <v-row>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-col class="pa-17">
          <v-text-field v-model="anio" label="Año" outlined dense hide-details></v-text-field>
        </v-col>

        <v-col class="pa-17">
          <v-autocomplete v-model="mes" :items="items_mes" item-text="mes" item-value="id_mes" label="Mes" outlined
            dense hide-details>

          </v-autocomplete>
        </v-col>

        <v-col class="pa-17">
          <v-autocomplete v-model="sucursal" :items="items_sucursales" item-text="nombre" item-value="id_sucursal"
            label="Sucursal" outlined dense hide-details>
            <template v-slot:selection="data">
              {{ data.item.cod_sucursal }} {{ data.item.nombre }}
            </template>
            <template v-slot:item="data">
              {{ data.item.cod_sucursal }} {{ data.item.nombre }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-dialog v-model="dialog_select_download" persistent max-width="900px" class="dialog-custom">
          <v-card>
            <v-card-title class="text-h6">Descargar Archivo CSV</v-card-title>
            <v-card-actions class="actions-custom">
              <v-btn color="cyan" @click="descargarArchivo('csv')" class="btn-custom">CSV</v-btn>
              <v-btn color="green" @click="dialog_select_download = false" class="btn-custom-cancel">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-dialog v-model="dialog_preview_report" fullscreen persistent>
          <v-toolbar flat dense>
            <v-toolbar-title> DOCUMENTO </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="dialog_preview_report = !dialog_preview_report">far fa-times-circle</v-icon>
          </v-toolbar>
          <iframe :src="base64_doc" id="myPDF" frameborder="0" />
        </v-dialog>


        <v-spacer></v-spacer>
      </v-toolbar>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn color="cyan" fab @click="abrirModalDescarga">
          <img src="@/assets/doc_fac.png" alt="Icono" class="button-image">
        </v-btn>
      </v-col>

    </v-row>


    <v-row justify="center">

    </v-row>

    <v-snackbar v-model="snackbar" timeout="4000" color="blue" multi-line elevation="24" outlined top right>
      <v-icon>fa-solid fa-circle-info</v-icon>
      {{ snackbar_text }}
    </v-snackbar>

  </v-container>
</template>

<script>


import axios from "axios";


export default {
  name: 'CircularButtons',
  data() {
    return {

      anio: "2025",
      mes: "",
      sucursal: "",

      //Requsitos
      tipoDocumento: "", // A 
      documento_identificador: "", // B 
      nombre: "", // C 
      fecha: "",  // D
      serie: "", // E 
      documento: "", // F 
      internas_importaciones: "", // G 
      iva: "", // H 
      tipo_operacion: "", // I 
      clasificacion: "",  // J 
      rubro: "", // K 
      costo_gastos: "", // L 
      numeroAnexo: "5", // M 

      dialog_preview_report: false,
      base64_doc: "",


      items_compras: [],
      items_sucursales: [],
      items_mes: [],
      items_fac: [],

      loading_compras: false,

      snackbar: false,
      snackbar_text: "",

      dialog_select_download: false,
    }
  },

  created() {
    this.listarSucursales();
    this.listarMes();
    this.listarProveedores();
    this.listarClientes();
    //this.generarReporte();
  },

  methods: {

    abrirModalDescarga() {
      this.dialog_select_download = true;
    },

    // Controla la selección del archivo (PDF o CSV)
    descargarArchivo(tipo) {
      if (tipo === 'pdf') {
        this.descargarFEX();
      } else if (tipo === 'csv') {

        this.obtenerDatosParaCSV();
      }
      this.dialog_select_download = false;
    },


    obtenerDatosParaCSV() {
      this.loading_compras = true;

      axios.get("api/Facturas/ListarAscendente/" + this.$store.state.emp.id_empresa)
        .then(response => {
          if (Array.isArray(response.data)) {

            const facturasData = response.data.filter(item => {
              const fecha = new Date(item.fecha);
              const itemAnio = fecha.getFullYear();
              const itemMes = fecha.getMonth() + 1;

              return itemAnio === parseInt(this.anio) && itemMes === parseInt(this.mes);
            }).map(item => {
              // Obtener cliente
              const cliente = this.getClienteByIdLocal(item.id_cliente);
              if (cliente) {
                item.nit = cliente.id_identificador == "1" && cliente.documento
                  ? cliente.documento.replace(/-/g, "")
                  : "";

                item.dui = cliente.id_identificador == "2" && cliente.documento
                  ? cliente.documento.replace(/-/g, "")
                  : "";

                item.nombre = cliente.nombre || "No disponible";
              } else {
                item.nit = "N/A";
                item.dui = "N/A";
                item.nombre = "Cliente no encontrado";
              }
              return item;
            });





            // Verifica si hay facturas para generar el CSV
            if (facturasData.length > 0) {
              this.descargarCSVFEX(facturasData);
            } else {
              this.snackbar_text = "No hay documentos para el año y mes seleccionados";
              this.snackbar = true;
            }
          } else {
            this.snackbar_text = "Error al obtener los datos de facturas";
            this.snackbar = true;
          }
        })
        .catch(error => {
          this.snackbar_text = "No se pudo obtener información de las facturas";
          this.snackbar = true;
        })
        .finally(() => {
          this.loading_compras = false;
        });
    },

    getProveedorByIdLocal(id_proveedor) {
      if (this.items_proveedores && this.items_proveedores[id_proveedor]) {
        return this.items_proveedores[id_proveedor];
      }
      return null;
    },

    getClienteByIdLocal(id_cliente) {
      if (this.items_clientes && this.items_clientes[id_cliente]) {
        return this.items_clientes[id_cliente];
      }
      return null;
    },


    listarFacturas() {
      this.loading_facturas = true;
      axios
        .get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items = response.data;
          this.loading_facturas = false;
        })
        .catch(() => {
          this.loading_facturas = false;
        });
    },


    listarSucursales() {
      axios
        .get("api/Sucursales/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_sucursales = response.data;
          this.sucursal = response.data[0].id_sucursal;
        });
    },

    listarMes() {
      axios.get("api/Mes/Listar")
        .then((response) => {
          this.items_mes = response.data;
        });
    },

    // Listar todos los proveedores 
    listarProveedores() {
      return axios
        .get("api/Proveedores/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_proveedores = response.data.reduce((map, proveedor) => {
            map[proveedor.id_proveedor] = proveedor;
            return map;
          }, {});


        })
        .catch((error) => {
          console.error("Error al listar los proveedores:", error);
          this.snackbar_text = "No se pudieron cargar los proveedores";
          this.snackbar = true;
        });
    },

    listarClientes() {
      return axios
        .get("api/Clientes/ListarTodos/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_clientes = response.data.reduce((map, cliente) => {
            map[cliente.id_cliente] = cliente;
            return map;
          }, {});


        })
        .catch((error) => {
          console.error("Error al listar los clientes:", error);
          this.snackbar_text = "No se pudieron cargar los clientes";
          this.snackbar = true;
        });
    },




    descargarFEX() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_facturas = true;

        // Obtener las facturas
        axios.get("api/Facturas/ListarAscendente/" + this.$store.state.emp.id_empresa)
          .then(response => {
            if (Array.isArray(response.data)) {
              // Filtrar los datos de CCF
              const ccfData = response.data.filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;

                return item.id_tipo_doc_fiscal == "10" &&
                  itemAnio === parseInt(this.anio) &&
                  itemMes === parseInt(this.mes);
              }).map(item => {
                // Obtener cliente
                const cliente = this.getClienteByIdLocal(item.id_cliente);
                if (cliente) {
                  item.nit = cliente.id_identificador == "1" && cliente.documento
                    ? cliente.documento.replace(/-/g, "")
                    : "";

                  item.dui = cliente.id_identificador == "2" && cliente.documento
                    ? cliente.documento.replace(/-/g, "")
                    : "";

                  item.nombre = cliente.nombre || "No disponible";
                  item.direccion = cliente.direccion || "No disponible";
                  item.registro = cliente.registro || "No disponible";
                  item.correo1 = cliente.correo1 || "No disponible";
                } else {
                  item.nit = "N/A";
                  item.dui = "N/A";
                  item.nombre = "Cliente no encontrado";
                }
                return item;
              });

              // Obtener los IDs de facturas
              const id_factura = ccfData.map(item => item.id_factura);

              if (id_factura.length > 0) {
                // Crear los parámetros para la solicitud
                const parametros = {
                  id_facturas: id_factura,
                  tipo_doc: "pdf", // Tipo Doc
                  server: "SERVER" // LOCAL o SERVER
                };

                axios.post("api/Facturas/ReporteCCF", parametros)
                  .then(response => {
                    const reporteURL = response.data;

                    // Descargar el archivo PDF
                    const link = document.createElement('a');
                    link.href = reporteURL;
                    link.setAttribute('download', `Reportes_CCF.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.base64_doc = "data:application/pdf;base64," + response.data;
                    this.dialog_preview_report = true;

                    this.snackbar_text = "Reporte generado exitosamente";
                    this.snackbar = true;


                    this.descargarCSVCCF(ccfData);
                  })
                  .catch(error => {
                    this.snackbar_text = "No se pudo generar el reporte CCF";
                    this.snackbar = true;
                  })
                  .finally(() => {
                    this.loading_facturas = false;
                  });

              } else {
                this.snackbar_text = "No hay documentos CCF para el año y mes seleccionados";
                this.snackbar = true;
                this.loading_facturas = false;
              }
            } else {
              this.snackbar_text = "Error en la respuesta del servidor";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            this.snackbar_text = "No se pudo descargar el archivo CCF";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
      }
    },


    descargarCSVFEX(data) {
      const csvContent = this.generarCSVCCF(data);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute("download", `Reporte_FEX_${this.anio}_${this.mes}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    },

    //Generador del Documento CSV-FSE



    generarCSVCCF(data) {
      // Filtrar datos por año y mes seleccionados
      const filteredData = data.filter(row => {
        const fecha = new Date(row.fecha);
        return fecha.getFullYear() === parseInt(this.anio) &&
          (fecha.getMonth() + 1) === parseInt(this.mes);
      });

      // Definir el header (encabezado) del CSV
      const header = [
        "Fecha", // A: Fecha Emisión (DD/MM/AAAA)
        "Resolucion", // B: Número de Serie (Sello de Recepción)
        "Codigo de Generacion", // C: Número de Documento (Código de Generación)
        "Numero de Control", // D: Número de Control
        "Tipo DOC", // E: Tipo de Documento (FEX)
        "NIT", // F: NIT Cliente sin guiones (Solo si id_identificador === "1")
        "Nombre Cliente", // G: Nombre Cliente
        "Correo", // H: Correo 1
        "Direccion", // I: Dirección
        "Registro", // J: Registro
        "IVA", // K: Débito Fiscal
        "Descuento", // L: Descuento
        "Exentas ", // M: Gravadas
        "Gravadas", // N: Exentas
        "No Sujetas", // O: No Sujetas
        "Retencion", // P: Retención
        "Percepcion", // Q: Percepción
        " Total Operacion", // R: Tipo de Operación
        "Total a Pagar", // S: Total a Pagar
        "DUI", // T: DUI Cliente (Solo si id_identificador === "2")
        "Anulado", // U: Resolución de Invalidación
      ];

      // Mapear los datos filtrados a filas formateadas
      const rows = filteredData.map(row => {


        return [
          this.formatDate(row.fecha), // A: Fecha Emisión (DD/MM/AAAA)
          row.sello_recepcion, // B: Número de Serie
          row.codigo_generacion, // C: Número de Documento
          row.numero_control, // D: Número de Control
          "FEX", // E: Tipo de Documento (FEX)
          row.nit || "", // F: NIT Cliente sin guiones (Solo si id_identificador === "1")
          row.nombre || "", // G: Nombre Cliente
          row.correo1 || "", // H: Correo 1
          row.direccion || "", // I: Dirección
          row.registro || "", // J: Registro
          row.iva || "0.00", // K: Débito Fiscal
          row.descuento || "", // L: Descuento
          row.gravadas || "", // M: Gravadas
          row.exentas || "", // N: Exentas
          row.no_sujetas || "", // O: No Sujetas
          row.retencion || "", // P: Retención
          row.percepcion || "", // Q: Percepción
          row.total_operacion || "", // R: Tipo de Operación
          row.total_pagar || "", // S: Total a Pagar
          row.dui || "", // T: DUI Cliente (Solo si id_identificador === "2")
          row.resolucion_invalidacion || "", // U: Resolución de Invalidación
        ].join(";");
      });

      // Retornar el CSV con el header seguido de las filas de datos
      return [header.join(";"), ...rows].join("\n");
    },



    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    },

    /*generarReporte() {
      axios.get('api/Factura/ReporteFAC')
        .then(response => {
          this.items_fac = response.data;
        })
        .catch(error => {
          console.error('Error al generar el reporte:', error);
        });
    },*/
  }
}
</script>


<style scoped>
#myPDF {
  width: 100%;
  height: 100%;
}


.v-btn {
  width: 190px;
  height: 190px;
  color: white;
}

.button-image {
  width: 140px;
  height: 140px;
}

.v-dialog {
  max-width: 400px;
}

.button-custom:hover {
  transform: scale(1.1);
}

.dialog-custom {
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.actions-custom {
  justify-content: center;
  padding: 16px;
}

.btn-custom {
  border-radius: 12px;
  margin: 8px;
  transition: background-color 0.3s ease;
}

.btn-custom:hover {
  background-color: #45a049;
}

.btn-custom-cancel {
  color: #757575;
  background-color: #f0f0f0;
  border-radius: 12px;
  margin: 8px;
  transition: background-color 0.3s ease;
}

.btn-custom-cancel:hover {
  background-color: #d3d3d3;
}

.v-dialog__content {
  padding: 0;
  /* Eliminar el padding */
}
</style>*/