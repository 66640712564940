<template>
  <v-container fluid>
    <v-row justify="center">
      <!-- ////////////////////////////////////VER EMPRESA/////////////////////////////////////////////////////////////// -->
      <v-col cols="12" class="text-center">
        <v-data-table :items="items_empresas" :headers="headers_empresas" dense class="elevation-1" disable-pagination
          hide-default-footer :search="search" :loading="loading">
          <template v-slot:top>
            <v-toolbar elevation="0">
              <v-btn color="green" dark @click="BusquedaEmpresa()">Recargar</v-btn>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" label="Buscar" outlined dense hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="NuevaEmpresa()">Nuevo</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.username`]="{ item }">
            <span>{{ recortarCadena(item.username) }}</span>
          </template>
          <template v-slot:[`item.pwd`]="{ item }">
            <span>{{ recortarCadena(item.pwd) }}</span>
          </template>
          <template v-slot:[`item.token`]="{ item }">
            <span>{{ recortarCadena(item.token) }}</span>
          </template>
          <template v-slot:[`item.opciones`]="{ item }">
            <v-icon @click="editarEmpresa(item)">fa-solid fa-pen-to-square</v-icon>
          </template>
        </v-data-table>
      </v-col>
      <!-- ////////////////////////////////////NUEVO, EDITAR EMPRESA/////////////////////////////////////////////////// -->
      <v-dialog v-model="dialog_empresa" width="800" persistent scrollable>
        <v-card>
          <v-card-title>
            EMPRESA
            <v-spacer></v-spacer>
            <v-img :src="'data:image/png;base64,' + this.logo" max-width="100" max-height="100" contain></v-img>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12" md="6">
                <v-text-field id="result" v-model="codigo" label="Código" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align-self="center">
                <v-file-input label="Logo" outlined dense id="myFileInput" hide-details></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="nombre" label="Nombre" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="direccion" label="Dirección" outlined dense hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="registro_fiscal" label="Registro fiscal" outlined dense hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="telefono" label="Teléfono" outlined dense hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="nit" label="NIT" outlined dense hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete v-model="departamento" :items="items_departamentos" item-text="nombre"
                  item-value="cod_MH" label="Departamento" outlined dense hide-details
                  @input="listarMunicipios(departamento)">
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete v-model="municipio" :items="items_municipios" item-text="nombre" item-value="cod_MH"
                  label="Distrito" outlined dense hide-details>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete v-model="municipio2" :items="items_municipios2" item-text="nom_muni2"
                  item-value="cod_muni_MH" label="Municipio" outlined dense hide-details>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="giro" label="Giro" outlined dense hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="correo" label="Correo" outlined dense hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete v-model="recinto_fiscal" :items="items_recinto_fiscal" label="Recinto fiscal"
                  item-text="nombre" item-value="cod_MH" outlined dense hide-details>
                  <template v-slot:selection="data">
                    {{ data.item.cod_MH }} {{ data.item.nombre }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.cod_MH }} {{ data.item.nombre }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="actividad_economica" :items="items_acti_economica" item-text="nombre"
                  item-value="cod_MH" label="Actividad Económica" outlined dense hide-details>
                  <template v-slot:selection="data">
                    {{ data.item.cod_MH.trim() }} {{ data.item.nombre }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.cod_MH.trim() }} {{ data.item.nombre }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="regimen_exportacion" :items="items_regimen_exportacion" item-text="nombre"
                  item-value="cod_MH" label="Régimen de exportación" outlined dense hide-details>
                  <template v-slot:selection="data">
                    {{ data.item.cod_MH }} {{ data.item.nombre }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.cod_MH }} {{ data.item.nombre }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="usuario" label="Usuario" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="contraseña" label="Contraseña" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="ruta_archivo" label="Ruta archivo" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="clave_privada" label="Clave privada" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="token" label="Token" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="expire" label="Expire token" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="nombre_db" label="Base de datos" outlined dense hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="rubro" :items="items_rubro" item-text="rubro" item-value="id_rubro"
                  label="Sector Empresa" outlined dense hide-details>
                  <template v-slot:selection="data">
                    {{ data.item.codigo }} {{ data.item.rubro }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.codigo }} {{ data.item.rubro }}
                  </template>
                </v-autocomplete>
              </v-col>
              
              <v-col cols="12" md="auto" align-self="center">
                <v-checkbox v-model="blocs" label="Activar blocs" dense hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" v-if="blocs">
                <v-text-field v-model="valor_restante" label="Restantes" outlined dense hide-details type="number"
                  min="0" @input="validarNumero()"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" v-if="blocs">
                <v-text-field v-model="valor_sumado" label="Sumados" outlined dense hide-details type="number" min="0"
                  @input="validarNumero()"></v-text-field>
              </v-col>

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="CrearEmpresa()" v-if="flag_edit_new == 1">Guardar</v-btn>
            <v-btn color="primary" @click="actualizarEmpresa()" v-if="flag_edit_new == 2">Actualizar</v-btn>
            <v-btn color="red" dark v-if="flag_edit_new == 2" @click="eliminarEmpresa()">Eliminar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="cerrarDialogEmpresa()">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="dialog_eliminar_empresa" width="500" persistent>
      <v-card>
        <v-toolbar elevation="0" dense>
          <v-toolbar-title>¿Esta seguro de eliminar la empresa?</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pb-0">
          <p class="title font-weight-bold">
            {{ nombre }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="confirmarEliminarEmpresa()">Si, Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_empresa = !dialog_eliminar_empresa">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="-1" color="blue" multi-line elevation="24" outlined top right>
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ text_snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>fa-regular fa-circle-xmark</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-model="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from "axios";
import settings from "../../settings/settings";

export default {
  data: () => ({
    search: "",
    snackbar: false,
    text_snackbar: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    overlay: false,
    //////////EMPRESA///////////////
    items_empresas: [],
    headers_empresas: [
      { text: "Código", value: "cod_empresa", sortable: true },
      { text: "Nombre", value: "nombre_empresa", sortable: false },
      { text: "Usuario", value: "username", sortable: false },
      { text: "Contraseña", value: "pwd", sortable: false },
      { text: "Token", value: "token", sortable: false },
      { text: "Editar", value: "opciones", align: "center", sortable: false },
    ],
    direccion: "",
    registro_fiscal: "",
    telefono: "",
    nit: "",
    giro: "",
    correo: "",
    items_acti_economica: [],
    actividad_economica: "",
    items_regimen_exportacion: [],
    regimen_exportacion: "",
    recinto_fiscal: "",
    items_recinto_fiscal: [],
    items_departamentos: [],
    items_municipios: [],
    items_municipios2: [],
    departamento: "",
    municipio: "",
    municipio2: "",
    dialog_empresa: false,
    id_empresa: 0,
    codigo: "",
    nombre: "",
    usuario: "",
    contraseña: "",
    ruta_archivo: "",
    clave_privada: "",
    token: "",
    expire: "",
    flag_edit_new: 1,
    nombre_logo: "",
    logo: null,
    fileByteArray: null,
    nombre_db: "",
    loading: false,
    dialog_eliminar_empresa: false,

    //Nuevo
    items_rubro: [],
    rubro: "",
    item: [],
    valor_restante: 0,
    valor_sumado: 0,
    blocs: false
  }),
  created() {
    this.BusquedaEmpresa();
    this.listarDepartamentos();
    this.listarRegimenExportacion();
    this.listarRecintoFiscales();
    this.listarActividadEconomica();
    this.listarRubro();
  },
  methods: {
    validarNumero() {
      if (this.valor_sumado < 0) {
        this.valor_sumado = 0;
      }

      if (this.valor_restante < 0) {
        this.valor_restante = 0;
      }
    },
    async listarDepartamentos() {
      this.items_departamentos = await (
        await settings.metodos.Departamentos()
      ).data;
    },
    async listarMunicipios(item) {
      if (!item) {
        return;
      }
      var departamento = await (
        await settings.metodos.DepartamentoCodMH(item)
      ).data;
      this.items_municipios = await (
        await settings.metodos.Municipios(departamento.cod_depa)
      ).data;
      this.items_municipios2 = await (
        await settings.metodos.Municipios2(departamento.cod_depa)
      ).data;
    },
    listarRegimenExportacion() {
      axios.get("api/RegimenExportacion/Listar").then((response) => {
        this.items_regimen_exportacion = response.data;
      });
    },
    listarRecintoFiscales() {
      axios.get("api/RecintoFiscal/Listar").then((response) => {
        this.items_recinto_fiscal = response.data;
      });
    },
    listarActividadEconomica() {
      axios.get("api/ActividadEconomicas/Listar").then((response) => {
        this.items_acti_economica = response.data;
      });
    },
    listarRubro() {
      axios.get("api/Rubro/Listar")
        .then((response) => {

          if (Array.isArray(response.data)) {
            this.items_rubro = response.data;
          } else {
            console.error("La API no devolvió un array:", response.data);
            this.items_rubro = []; // Siempre sea un array
          }
        })
        .catch((error) => {
          console.error("Error al obtener rubros:", error);
          this.items_rubro = [];
        });
    },

    BusquedaEmpresa() {
      this.loading = true;
      axios
        .get("api/UsuariosXEmpresas/Listar")
        .then((response) => {
          //console.log(response.data);
          this.items_empresas = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    CrearEmpresa() {
      if (!this.codigo) {
        this.text_snackbar = "Campos Código no puede quedar vacio";
        this.snackbar = true;
        return;
      }

      this.overlay = true;
      axios
        .put("api/DetallesEmpresas/Crear", {
          cod_empresa: this.codigo,
          nombre_empresa: this.nombre,
          direccion: this.direccion,
          registro_fiscal: this.registro_fiscal,
          telefono: this.telefono,
          nit: this.nit,
          departamento: this.departamento,
          municipio: this.municipio,
          municipio2: this.municipio2,
          giro: this.giro,
          correo: this.correo,
          recinto_fiscal: this.recinto_fiscal,
          codigo_actividad: this.actividad_economica,
          regimen_exportacion: this.regimen_exportacion,
          username: this.usuario,
          pwd: this.contraseña,
          ruta_archivo: this.ruta_archivo,
          clave_privada: this.clave_privada,
          nombre_db: this.nombre_db,

          rubro: this.rubro,

          bloc: this.blocs,
          bloc_restante: this.valor_restante,
          bloc_sumados: this.valor_sumado
        })
        .then((response) => {
          this.cerrarDialogEmpresa();
          this.BusquedaEmpresa();
          this.text_snackbar = response.data;
          this.snackbar = true;
          this.overlay = false;
          this.alerta("fa-solid fa-check", response.data);
        })
        .catch((error) => {
          this.snackbar = true;
          this.overlay = false;
          this.alerta("fa-solid fa-xmark", "No se pudo crear el registro");
        });
    },
    async actualizarEmpresa() {
      this.overlay = true;
      axios
        .put("api/DetallesEmpresas/Actualizar", {
          id_empresa: this.id_empresa,
          cod_empresa: this.codigo,
          nombre_empresa: this.nombre,
          direccion: this.direccion,
          registro_fiscal: this.registro_fiscal,
          telefono: this.telefono,
          nit: this.nit,
          departamento: this.departamento,
          municipio: this.municipio,
          municipio2: this.municipio2,
          giro: this.giro,
          correo: this.correo,
          recinto_fiscal: this.recinto_fiscal,
          codigo_actividad: this.actividad_economica,
          regimen_exportacion: this.regimen_exportacion,
          username: this.usuario,
          pwd: this.contraseña,
          ruta_archivo: this.ruta_archivo,
          clave_privada: this.clave_privada,
          token: this.token,
          expire_token: this.expire,
          nombre_logo: this.nombre_logo,
          logo: await this.convertImage(),
          nombre_db: this.nombre_db,

          rubro: this.rubro,

          bloc: this.blocs,
          bloc_restante: this.valor_restante,
          bloc_sumados: this.valor_sumado
        })
        .then(() => {
          this.dialog_empresa = false;
          this.overlay = false;
          this.alerta("fa-solid fa-check", "Actualizado con exito");
          this.cerrarDialogEmpresa();
          this.BusquedaEmpresa();
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("fa-solid fa-xmark", "No se pudo actualizar el registro");
        });
    },
    NuevaEmpresa() {
      this.flag_edit_new = 1;
      this.dialog_empresa = true;
    },
    editarEmpresa(item) {
      //console.log(item);      
      this.flag_edit_new = 2;
      this.id_empresa = item.id_empresa;
      this.codigo = item.cod_empresa;
      this.nombre = item.nombre_empresa;
      this.direccion = item.direccion;
      this.registro_fiscal = item.registro_fiscal;
      this.telefono = item.telefono;
      this.nit = item.nit;
      this.departamento = item.departamento;
      this.municipio = item.municipio;
      this.municipio2 = item.municipio2;
      this.giro = item.giro;
      this.correo = item.correo;
      this.recinto_fiscal = item.recinto_fiscal;
      this.actividad_economica = item.codigo_actividad;
      this.regimen_exportacion = item.regimen_exportacion;
      this.usuario = item.username;
      this.contraseña = item.pwd;
      this.ruta_archivo = item.ruta_archivo;
      this.clave_privada = item.clave_privada;
      this.token = item.token;
      this.expire = item.expire_token;
      this.logo = item.logo;
      this.nombre_logo = item.nombre_logo;
      this.nombre_db = item.nombre_db;
      this.blocs = item.bloc;
      this.valor_restante = item.bloc_restante;
      this.valor_sumado = item.bloc_sumados;

      this.rubro = item.rubro;

      this.listarMunicipios(this.departamento);

      this.dialog_empresa = true;
    },
    cerrarDialogEmpresa() {
      this.dialog_empresa = false;
      this.limpiarVarEmpresas();
    },
    limpiarVarEmpresas() {
      this.codigo = "";
      this.nombre = "";
      this.direccion = "";
      this.registro_fiscal = "";
      this.telefono = "";
      this.nit = "";
      this.departamento = "";
      this.municipio = "";
      this.municipio2 = "";
      this.giro = "";
      this.correo = "";
      this.recinto_fiscal = "";
      this.actividad_economica = "";
      this.regimen_exportacion = "";
      this.usuario = "";
      this.contraseña = "";
      this.ruta_archivo = "";
      this.clave_privada = "";
      this.token = "";
      this.expire = "";
      this.logo = "";
      this.nombre_logo = "";
      this.nombre_db = "";
      this.rubro = "";

      this.blocs = false;
      this.valor_restante = 0;
      this.valor_sumado = 0;
    },
    alerta(icon, mensaje) {
      this.icon_snackbar = icon;
      this.text_snackbar = mensaje;
      this.snackbar = true;
    },
    async convertImage() {
      if (document.getElementById("myFileInput").files[0] == undefined) {
        return null;
      }
      let myFile = document.getElementById("myFileInput").files[0];

      this.nombre_logo = myFile.name;
      if (myFile == undefined) {
        return null;
      }
      let byteArray = await this.fileToByteArray(myFile);
      return byteArray;
    },
    fileToByteArray(file) {
      return new Promise((resolve, reject) => {
        try {
          let reader = new FileReader();
          let fileByteArray = [];
          reader.readAsArrayBuffer(file);
          reader.onloadend = (evt) => {
            if (evt.target.readyState == FileReader.DONE) {
              let arrayBuffer = evt.target.result,
                array = new Uint8Array(arrayBuffer);
              for (var i = 0; i < array.length; i++) {
                fileByteArray.push(array[i]);
              }
            }
            resolve(fileByteArray);
          };
        } catch (e) {
          reject(e);
        }
      });
    },
    recortarCadena(item) {
      return item != null ? item.substring(0, 10) + "..." : "...";
    },
    eliminarEmpresa() {
      this.dialog_eliminar_empresa = true;
    },
    confirmarEliminarEmpresa() {
      axios
        .delete("api/DetallesEmpresas/Eliminar/" + this.id_empresa)
        .then((response) => {
          if (response.data == 400) {
            this.text_snackbar =
              "No se puede eliminar la empresa, tiene movimientos relacionados";
            this.snackbar = true;
            return;
          }
          this.text_snackbar = "Empresa eliminada con exito";
          this.snackbar = true;
          this.BusquedaEmpresa();
          this.dialog_eliminar_empresa = false;
          this.dialog_empresa = false;
        })
        .catch(() => {
          this.text_snackbar = "No se pudo eliminar la empresa";
          this.snackbar = true;
        });
    },
  },
};
</script>