import { render, staticRenderFns } from "./PersonalSoftIVA.vue?vue&type=template&id=4bf67653&scoped=true&"
import script from "./PersonalSoftIVA.vue?vue&type=script&lang=js&"
export * from "./PersonalSoftIVA.vue?vue&type=script&lang=js&"
import style0 from "./PersonalSoftIVA.vue?vue&type=style&index=0&id=4bf67653&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf67653",
  null
  
)

export default component.exports