<template>
  <v-container fluid v-if="tiene_empresa">
    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-toolbar-title>Configuración SMTP</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevaConfiguracion()">Nuevo</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon @click="editarItem(item)"> fa-solid fa-pen-to-square </v-icon>
      </template>
      <template v-slot:[`item.ssl`]="{ item }">
        <v-chip :color="item.ssl ? 'green' : 'red'" dark>
          <span v-if="item.ssl">SI</span>
          <span v-else>NO</span>
        </v-chip>
      </template>
      <template v-slot:[`item.autenticar`]="{ item }">
        <v-chip :color="item.autenticar ? 'green' : 'red'" dark>
          <span v-if="item.autenticar">SI</span>
          <span v-else>NO</span>
        </v-chip>
      </template>
      <template v-slot:[`item.tls`]="{ item }">
        <v-chip :color="item.tls ? 'green' : 'red'" dark>
          <span v-if="item.tls">SI</span>
          <span v-else>NO</span>
        </v-chip>
      </template>
      <template v-slot:[`item.token`]="{ item }">
        <v-chip :color="item.token ? 'green' : 'red'" dark>
          <span v-if="item.token">SI</span>
          <span v-else>NO</span>
        </v-chip>
      </template>
      <template v-slot:[`item.enviar_correo`]="{ item }">
        <v-chip :color="item.enviar_correo ? 'green' : 'red'" dark>
          <span v-if="item.enviar_correo">SI</span>
          <span v-else>NO</span>
        </v-chip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="800" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="servidor"
                label="Servidor"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="puerto"
                label="Puerto"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="usuario"
                label="Usuario"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="correo"
                label="Correo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="contraseña"
                label="Contraseña"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="client_id"
                label="Client Id"
                outlined
                dense
                hide-details
                v-if="token"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="client_secret"
                label="Client Secret"
                outlined
                dense
                hide-details
                v-if="token"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="tenant_id"
                label="Tenant Id"
                outlined
                dense
                hide-details
                v-if="token"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-model="usa_ssl"
                label="Usa SSL"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-model="autenticar"
                label="Autenticar"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-model="tls"
                label="TLS"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-model="token"
                label="Usa Token"
                hide-details
                class="mt-0"
                @click="restablecerInputs()"
              ></v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-checkbox
                v-model="enviar_correo"
                label="Enviar correo"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="flag_edit_new == 1"
            @click="crearRegistro()"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            v-if="flag_edit_new == 2"
            @click="actualizarRegistro()"
            >Actualizar</v-btn
          >
          <v-btn
            color="red"
            dark
            v-if="flag_edit_new == 2"
            @click="confirmarEliminarConfiguracion()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_eliminar_configuracion" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar la configuración?</v-card-title>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarConfiguracion()"
            >Si, eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text>Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import * as CryptoJS from "crypto-js";

export default {
  data: () => ({
    headers: [
      { text: "Servidor", value: "servidor" },
      { text: "Puerto", value: "puerto" },
      { text: "SSL", value: "ssl", align: "center" },
      { text: "AUTH", value: "autenticar", align: "center" },
      { text: "TLS", value: "tls", align: "center" },
      { text: "TOKEN", value: "token", align: "center" },
      { text: "Enviar correo", value: "enviar_correo", align: "center" },
      { text: "Editar", value: "action_edit", align: "center" },
    ],
    items: [],
    dialog: false,
    id_confi_smtp: 0,
    servidor: "",
    puerto: "",
    usuario: "",
    correo: "",
    contraseña: "",
    usa_ssl: false,
    flag_edit_new: 0,
    autenticar: false,
    enviar_correo: false,
    tls: false,
    token: false,
    client_id: "",
    client_secret: "",
    tenant_id: "",
    dialog_eliminar_configuracion: false,
  }),
  created() {
    //
  },
  computed: {
    tiene_empresa() {
      if (this.$store.state.emp) {
        this.listar();
        return true;
      }
    },
  },
  methods: {
    async listar() {
      if (this.$store.state.emp.id_empresa != undefined)
        axios
          .get("api/ConfiSmtp/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            //console.log(response.data);

            this.items = response.data;
          });
    },
    nuevaConfiguracion() {
      this.flag_edit_new = 1;
      this.dialog = true;
    },
    crearRegistro() {
      axios
        .post("api/ConfiSmtp/Crear", {
          id_empresa: this.$store.state.emp.id_empresa,
          servidor: this.servidor,
          puerto: this.puerto,
          usuario: this.usuario,
          correo: this.correo,
          contraseña: this.contraseña,
          ssl: this.usa_ssl,
          autenticar: this.autenticar,
          enviar_correo: this.enviar_correo,
          tls: this.tls,
          usar_token: this.token,
          client_id: this.client_id,
          client_secret: this.client_secret,
          tenant_id: this.tenant_id,
        })
        .then(() => {
          this.listar();
          this.cerrarDialog();
        });
    },
    actualizarRegistro() {
      axios
        .put("api/ConfiSmtp/Actualizar", {
          id_confi_smtp: this.id_confi_smtp,
          id_empresa: this.$store.state.emp.id_empresa,
          servidor: this.servidor,
          puerto: this.puerto,
          usuario: this.usuario,
          correo: this.correo,
          contraseña: this.contraseña,
          ssl: this.usa_ssl,
          autenticar: this.autenticar,
          enviar_correo: this.enviar_correo,
          tls: this.tls,
          usar_token: this.token,
          client_id: this.client_id,
          client_secret: this.client_secret,
          tenant_id: this.tenant_id,
        })
        .then(() => {
          this.listar();
          this.cerrarDialog();
        });
    },
    editarItem(item) {
      //console.log(item);
      this.id_confi_smtp = item.id_confi_smtp;
      this.servidor = item.servidor;
      this.puerto = item.puerto;
      this.usuario = item.usuario;
      this.correo = item.correo;
      this.contraseña = item.contraseña;
      this.usa_ssl = item.ssl;
      this.autenticar = item.autenticar;
      this.enviar_correo = item.enviar_correo;
      this.tls = item.tls;
      this.token = item.usar_token;
      this.client_id = item.client_id;
      this.client_secret = item.client_secret;
      this.tenant_id = item.tenant_id;

      this.flag_edit_new = 2;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    confirmarEliminarConfiguracion() {
      this.dialog_eliminar_configuracion = true;
    },
    eliminarConfiguracion() {
      axios.delete("api/ConfiSmtp/Eliminar/" + this.id_confi_smtp).then(() => {
        this.dialog_eliminar_configuracion = false;
        this.listar();
        this.cerrarDialog();
      });
    },
    limpiar() {
      this.id_confi_smtp = 0;
      this.servidor = "";
      this.puerto = "";
      this.usuario = "";
      this.correo = "";
      this.contraseña = "";
      this.usa_ssl = false;
      this.autenticar = false;
      this.enviar_correo = false;
      this.tls = false;
      this.token = false;
      this.client_id = "";
      this.client_secret = "";
      this.tenant_id = "";
    },
    restablecerInputs() {
      this.client_id = "";
      this.client_secret = "";
      this.tenant_id = "";
    },
    decryptUsingAES256(decString) {
      if (decString == null) {
        return "";
      }

      if (decString == "") {
        return "";
      }

      this.key = CryptoJS.enc.Utf8.parse("4512631236589784");
      this.iv = CryptoJS.enc.Utf8.parse("4512631236589784");

      var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    },
  },
};
</script>