<template>
    <v-container fluid>
      <ReporteConab v-if="$store.state.emp.cod_empresa == 383"/>    
      <ReporteSwisstex v-if="$store.state.emp.cod_empresa == 381"/>
      <ReporteUnique v-if="$store.state.emp.cod_empresa == 380"/>
      <ReportePersonalSoft v-if="$store.state.emp.cod_empresa == 398"/>
    </v-container>
  </template>
  
  <script>
  import ReporteConab from './Conab/ReporteVentaCompra.vue';
  import ReporteSwisstex from './Swisstex/ReporteVentaCompra.vue';
  import ReporteUnique from './Unique/ReporteVentaCompra.vue'
  import ReportePersonalSoft from './PersonalSoft/ReporteVentaCompra.vue'
  
  export default {
    data: () => ({
      //
    }),
    components: {
        ReporteConab,
        ReporteSwisstex,
        ReporteUnique,
        ReportePersonalSoft,
    },
  };
  </script>