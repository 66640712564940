<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="fecha_desde"
          label="Desde"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="fecha_hasta"
          label="Hasta"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="descargarFacturasJson()"
          >Formato JSON</v-btn
        >
      </v-col>
      <v-col cols="auto" v-if="progress">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      timeout="-1"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon color="orange">{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      fecha_desde: "",
      fecha_hasta: "",
      //otros
      snackbar: false,
      snackbar_text: "",
      snackbar_icon: "fa-regular fa-lightbulb",
      obj_fac: [],
      progress: false
    };
  },
  methods: {
    async descargarFacturasJson() {
      if (!this.fecha_desde && !this.fecha_hasta) {
        this.snackbar_text = "Debe de establecer las fechas primero";
        this.snackbar = true;
        return;
      }

      this.progress = true;

      this.obj_fac = await axios.post("api/Facturas/ExportarDocumentos", {
        id_empresa: this.$store.state.emp.id_empresa,
        desde: this.fecha_desde,
        hasta: this.fecha_hasta,
      });
      
      if (this.obj_fac.length == 0) {
        this.progress = false;
        return;
      }
      var filename = "Facturas.json";
      var blob = new Blob([JSON.stringify(this.obj_fac.data)], { type: "text/plain" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
        this.progress = false;
      } else {
        var e = document.createEvent("MouseEvents"),
          a = document.createElement("a");
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ["text/plain", a.download, a.href].join(":");
        e.initEvent("click",true,false,window,0,0,0,0,0,false,false,false,false,0,null);
        a.dispatchEvent(e);
        this.progress = false;
      }
    },
  },
};
</script>