<template>
  <v-container fluid>
    <fe-pruebas v-if="$store.state.emp.cod_empresa == 468" />
    <fe-swisstex v-if="$store.state.emp.cod_empresa == 381" />
    <fe-conab v-if="$store.state.emp.cod_empresa == 383" />
    <fe-unique v-if="$store.state.emp.cod_empresa == 380" />
    <fe-ib-marker v-if="$store.state.emp.cod_empresa == 469" />
    <fe-txool v-if="$store.state.emp.cod_empresa == 445" />
    <fe-personal-soft v-if="$store.state.emp.cod_empresa == 398" />
    <fe-hygge-kidz v-if="$store.state.emp.cod_empresa == 460" />
    <fepanacom v-if="$store.state.emp.cod_empresa == 414" />
    <fe-naka v-if="$store.state.emp.cod_empresa == 466" />
    <div
      v-if="
        $store.state.emp.cod_empresa != 468 &&
        $store.state.emp.cod_empresa != 381 &&
        $store.state.emp.cod_empresa != 383 &&
        $store.state.emp.cod_empresa != 380 &&
        $store.state.emp.cod_empresa != 469 &&
        $store.state.emp.cod_empresa != 445 &&
        $store.state.emp.cod_empresa != 398 &&
        $store.state.emp.cod_empresa != 460 &&
        $store.state.emp.cod_empresa != 414 &&
        $store.state.emp.cod_empresa != 466
      "
    >
      <lottie
        :options="defaultOptions"
        :height="700"
        :width="700"
        v-on:animCreated="handleAnimation"
      />
    </div>
  </v-container>
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "../../animations/page_not_found.json";

import fePruebas from "../Facturacion/FePruebas.vue";

import feSwisstex from "../Facturacion/Swisstex/FeSwisstex.vue";
import feConab from "../Facturacion/Conab/FeConab.vue";
import feUnique from "../Facturacion/Unique/FeUnique.vue";
import feIbMarker from "../Facturacion/IBMaker/FeIBMarker.vue";
import feTxool from "./Txool/FeTxool.vue";
import fePersonalSoft from "./PersonalSoft/FePersonalSoft.vue";
import feHyggeKidz from "./HyggeKidz/FeHyggeKidz.vue"
import Fepanacom from "../Facturacion/Panacom/Fepanacom.vue";
import FeNaka from "../Facturacion/Naka/FeNaka.vue";

export default {
  data: () => ({
    defaultOptions: { animationData: animationData },
    animationSpeed: 1,
  }),
  components: {
    Lottie,
    fePruebas,
    feSwisstex,
    feConab,
    feUnique,
    feIbMarker,
    feTxool,
    fePersonalSoft,
    feHyggeKidz,
    Fepanacom,
    FeNaka
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
};
</script>