<template>
  <v-container fluid v-if="tiene_empresa">
    <v-data-table :headers="headers" :items="items" :search="search_cliente">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Clientes</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search_cliente" label="Buscar" outlined dense hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoCliente()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.cliente_activo`]="{ item }">
        <v-chip color="green" dark v-if="item.cliente_activo">ACTIVO</v-chip>
        <v-chip color="red" dark v-else>INACTIVO</v-chip>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarCliente(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>
    </v-data-table>

    <!-- VENTANA PARA PODER VER, CREAR O ACTUALIZAR UN CLIENTE -->
    <v-dialog v-model="dialog" width="1000" persistent>
      <v-card>
        <v-card-title>
          <v-checkbox v-model="cliente_x" label="CLIENTE X" dense hide-details></v-checkbox>
          <v-spacer></v-spacer>
          <v-checkbox v-model="cliente_activo" label="CLIENTE ACTIVO" dense hide-details></v-checkbox>
        </v-card-title>
        <!-- FECHA, TIPO DOC, CODIGO, NOMBRE ... -->
        <v-row no-gutters class="my-1">
          <v-col cols="12">
            <v-card class="mx-3" elevation="0" style="border: 1px solid black">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field v-model="fecha_ingreso" label="Fecha ingreso" outlined dense hide-details
                      type="date"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete v-model="id_tipo_doc_fiscal" :items="items_tipo_doc_fiscal" item-text="nombre"
                      item-value="id_tipo_doc_fiscal" label="Tipo documento fiscal" outlined dense hide-details
                      :error="errorTipoDocFiscal"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field v-model="codigo" label="Código" outlined dense hide-details
                      :error="errorCodigo"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-1">
                    <v-text-field v-model="nombre" label="Nombre" outlined dense hide-details
                      :error="errorNombre"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-1">
                    <v-text-field v-model="razon_social" label="Razón social" outlined dense hide-details
                      :error="errorRazonSocial"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete v-model="id_departamento" :items="items_departamento" item-text="nombre"
                      item-value="id_departamento" label="Departamento" outlined dense hide-details
                      @input="listarMunicipios(id_departamento)" :error="errorDepartamento"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete v-model="id_municipio" :items="items_municipio" item-text="nombre"
                      item-value="id_municipio" label="Municipio" outlined dense hide-details
                      :error="errorMunicipio"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="pa-1">
                    <v-text-field v-model="direccion" label="Dirección" outlined dense hide-details
                      :error="errorDireccion"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="8" class="pa-1">
                    <v-autocomplete v-model="id_actividad_economica" :items="items_actividad_economica"
                      item-value="id_actividad_economica" item-text="nombre" label="Actividad Económica" outlined dense
                      hide-details :error="errorActividadEconomica">
                      <template v-slot:selection="data">
                        {{ data.item.cod_MH.trim() }} {{ data.item.nombre }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.cod_MH.trim() }} {{ data.item.nombre }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete v-model="tamaño" :items="items_tamaño" label="Tamaño" outlined dense hide-details
                      :error="errorTamaño"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field v-model="registro" label="Registro" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field v-model="email_1" label="Email" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field v-model="email_2" label="Email 2" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="8" class="pa-1">
                    <v-text-field v-model="contacto" label="Contacto" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field v-model="telefono" label="Teléfono" outlined dense hide-details
                      :error="errorTelefono"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete v-model="tipo_persona" :items="items_tipo_persona" label="Tipo" outlined dense
                      hide-details :error="errorTipoPersona"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete v-model="id_identificador" :items="items_identificador" item-text="nombre"
                      item-value="id_identificador" label="Identificador" outlined dense hide-details
                      :error="errorIdentificador"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field v-model="documento" label="Documento" outlined dense hide-details
                      :error="errorDocumento"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- CHECKBOX ... -->
        <v-row no-gutters class="my-1">
          <v-col cols="12">
            <v-card class="mx-3" elevation="0" outlined style="border: 1px solid black">
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="12" md="auto">
                    <v-checkbox v-model="con_retencion" label="CON RETENCION" dense hide-details
                      @change="estadoRetencion()"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-checkbox v-model="con_percepcion" label="CON PERCEPCION" dense hide-details
                      @change="estadoPercepcion()"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-checkbox v-model="exento" label="EXENTO" dense hide-details
                      @click="seleccionExento()"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" align-self="center" v-if="false">
            <v-card class="mx-3" elevation="3">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-checkbox v-model="con_credito" label="CON CREDITO" dense hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox v-model="exede_credito" label="EXEDE CREDITO" dense hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field v-if="false" v-model="limite_credito" label="Limite de credito" outlined dense
                      hide-details type="number"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field v-if="false" v-model="maximo_dias_limite" label="Maximo días limite" outlined dense
                      hide-details type="number"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- EXTRANJERO, TIPO EXPORT, ZONA, PAIS -->
        <v-row no-gutters class="my-1">
          <v-col cols="12">
            <v-card class="mx-3" elevation="0" outlined style="border: 1px solid black">
              <v-card-text>
                <v-row no-gutters justify="space-between">
                  <v-col cols="auto" md="3">
                    <v-checkbox v-model="extranjero" label="EXTRANJERO" dense hide-details
                      @click="seleccionExtranjero()"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="5" class="pa-1">
                    <v-autocomplete v-model="id_tipo_exportacion" :items="items_tipo_export" item-text="nombre"
                      item-value="id_tipo_exportacion" label="Tipo exportacion" outlined dense hide-details
                      :error="errorTipoExportacion"></v-autocomplete>
                  </v-col>
                  <v-col cols="auto" md="3">
                    <v-checkbox v-model="es_zona_franca_dpa" label="ES ZONA FRANCA O D.P.A." dense
                      hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1 mx-auto">
                    <v-autocomplete v-model="id_pais" :items="items_paises" item-text="nombre" item-value="id_pais"
                      label="País" outlined dense hide-details :error="errorPais"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-btn color="primary" @click="crearCliente()" v-if="flag_nuevo_edit == 1">Guardar</v-btn>
          <v-btn color="primary" @click="actualizarCliente()" v-if="flag_nuevo_edit == 2">Actualizar</v-btn>
          <v-btn color="red" dark @click="eliminarCliente()" v-if="flag_nuevo_edit == 2">Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogCliente()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA PARA MOSTRAR LOS PAISES -->
    <v-dialog v-model="dialog_paises" width="500">
      <v-data-table :headers="headers_paises" :items="items_paises" :search="search" @dblclick:row="setPais">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Países</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" label="BUSCAR" outlined dense hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">fa-solid fa-circle-info</v-icon>
              </template>
              <span>Doble click para seleccionar el país</span>
            </v-tooltip>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="dialog_eliminar_cliente" width="500" persistent>
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el cliente? <br />
          {{ nombre }}
        </v-card-title>
        <v-card-actions>
          <v-btn color="red" dark @click="confirmarEliminarCliente()">Si, eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_cliente = !dialog_eliminar_cliente">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="-1" color="blue" multi-line elevation="24" outlined top right>
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>fa-regular fa-circle-xmark</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import settings from "../../settings/settings";
import moment from "moment";

export default {
  data: () => ({
    snackbar: false,
    snackbar_text: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    search_cliente: "",
    search: "",
    headers: [
      { text: "Cliente", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Registro", value: "registro" },
      { text: "Identificador", value: "identificador_nombre" },
      { text: "Teléfono", value: "telefono" },
      { text: "Tipo", value: "tipo_persona" },
      { text: "Estatus", value: "cliente_activo", align: "center" },
      { text: "Editar", value: "action_edit", sortable: false },
    ],
    items: [],
    id_empresa: 0,
    //CLIENTE
    id_cliente: 0,
    fecha_ingreso: settings.metodos.formatoFecha(new Date()),
    id_tipo_doc_fiscal: "",
    items_tipo_doc_fiscal: [
      {
        id_tipo_doc_fiscal: 10,
        cod_doc_fiscal: "CCF",
        nombre: "COMPROBANTE DE CREDITO FISCAL",
        cod_MH: "03",
      },
      {
        id_tipo_doc_fiscal: 11,
        cod_doc_fiscal: "FAC",
        nombre: "FACTURA DE CONSUMIDOR FINAL",
        cod_MH: "01",
      },
      {
        id_tipo_doc_fiscal: 12,
        cod_doc_fiscal: "FEX",
        nombre: "FACTURA DE EXPORTACION",
        cod_MH: "11",
      },
    ],
    codigo: "",
    nombre: "",
    razon_social: "",
    id_departamento: null,
    items_departamento: [],
    id_municipio: null,
    items_municipio: [],
    direccion: "",
    id_actividad_economica: "",
    items_actividad_economica: [],
    tamaño: "",
    items_tamaño: ["PEQUEÑO", "MEDIANO", "GRANDE", "OTROS"],
    registro: "",
    email_1: "",
    email_2: "",
    contacto: "",
    telefono: "",
    tipo_persona: "",
    items_tipo_persona: ["NATURAL", "JURIDICA"],
    id_identificador: "",
    items_identificador: [],
    documento: "",
    con_retencion: false,
    cliente_activo: true,
    cliente_x: false,
    exento: false,
    con_percepcion: false,
    con_credito: false,
    exede_credito: false,
    limite_credito: "",
    maximo_dias_limite: "",
    extranjero: false,
    id_tipo_exportacion: "",
    items_tipo_export: [],
    es_zona_franca_dpa: false,
    headers_paises: [
      { text: "Codigo", value: "cod_pais", align: "center" },
      { text: "Nombre", value: "nombre" },
    ],
    id_pais: "",
    items_paises: [],
    dialog: false,
    dialog_paises: false,
    flag_nuevo_edit: 1,
    errorCodigo: false,
    errorNombre: false,
    errorRazonSocial: false,
    errorDepartamento: false,
    errorMunicipio: false,
    errorDireccion: false,
    errorActividadEconomica: false,
    errorTamaño: false,
    errorTelefono: false,
    errorTipoPersona: false,
    errorIdentificador: false,
    errorDocumento: false,
    errorTipoDocFiscal: false,
    errorPais: false,
    errorTipoExportacion: false,
    dialog_eliminar_cliente: false,
  }),
  created() {
    /*this.listarClientes();
    this.listarDepartamentos();
    this.listarIdentificadores();
    this.listarTipoExportacion();
    this.listarActividadEconomica();
    this.listarPaises("nuevo");*/
  },

  watch: {
    cliente_x(val) {
      if (val) {

        this.obtenerDatosEmpresa(this.$store.state.emp.id_empresa);

        this.direccion = 'SAN SALVADOR';
        this.telefono = '0000-0000';  
        this.id_actividad_economica = 771;
        this.documento = "00000000-0";
        this.id_identificador = 2
        this.tamaño = "OTROS";
        this.id_tipo_doc_fiscal = 11;

        this.id_departamento = 2;
        this.listarMunicipios(this.id_departamento).then(() => {

          this.id_municipio = this.items_municipio.find(municipio => municipio.nombre === 'SAN SALVADOR').id_municipio;
        });
      }
    }
  },

  computed: {
    tiene_empresa() {
      if (this.$store.state.emp) {
        this.listarClientes();
        this.listarDepartamentos();
        this.listarIdentificadores();
        this.listarTipoExportacion();
        this.listarActividadEconomica();
        this.listarPaises("nuevo");
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {


    async obtenerDatosEmpresa(id_empresa) {
      try {
        const response = await axios.get(`api/DetallesEmpresas/BusquedaEmpresa/${id_empresa}`);

        const datos = response.data[0];

        this.telefono = datos.telefono;

        //this.id_actividad_economica = datos.id_actividad_economica;
        //this.documento = datos.dui;
        this.email_1 = datos.correo;
        


      } catch (error) {
        console.error('Error al obtener los datos de la API', error);
      }
    },

    listarActividadEconomica() {
      axios.get("api/ActividadEconomicas/Listar").then((response) => {
        this.items_actividad_economica = response.data;
      });
    },
    listarClientes() {
      if (this.$store.state.emp.id_empresa != undefined)
        axios
          .get("api/Clientes/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            //console.log(response.data);
            this.items = response.data;
          });
    },
    nuevoCliente() {
      this.flag_nuevo_edit = 1;
      this.dialog = true;
    },
    crearCliente() {
      if (this.validarFormulario()) {
        axios
          .post("api/Clientes/Crear", {
            id_tipo_doc_fiscal: this.id_tipo_doc_fiscal,
            id_departamento: this.id_departamento,
            id_municipio: this.id_municipio,
            id_identificador: this.id_identificador,
            id_pais: this.id_pais,
            id_tipo_exportacion: this.id_tipo_exportacion,
            id_empresa: this.$store.state.emp.id_empresa,
            fecha_ingreso: this.fecha_ingreso,
            codigo: this.codigo,
            nombre: this.nombre,
            razon_social: this.razon_social,
            direccion: this.direccion,
            id_actividad_economica: this.id_actividad_economica,
            tamaño: this.tamaño,
            registro: this.registro,
            correo_1: this.email_1,
            correo_2: this.email_2,
            contacto: this.contacto,
            telefono: this.telefono,
            tipo_persona: this.tipo_persona,
            documento: this.documento.trim(),
            con_retencion: this.con_retencion,
            cliente_activo: this.cliente_activo,
            cliente_x: this.cliente_x,
            exento: this.exento,
            con_percecion: this.con_percepcion,
            con_credito: this.con_credito,
            exede_credito: this.exede_credito,
            limite_credito: this.limite_credito,
            max_dias_limite: this.maximo_dias_limite,
            extranjero: this.extranjero,
            zona_franca_dpa: this.es_zona_franca_dpa,
          })
          .then(() => {
            this.snackbar_text = "Registro creado.";
            this.snackbar = true;
            this.cerrarDialogCliente();
            this.listarClientes();
          })
          .catch(() => {
            this.snackbar_text = "No se pudo crear.";
            this.snackbar = true;
          });
      }
    },
    editarCliente(item) {
      this.id_cliente = item.id_cliente;
      this.id_empresa = item.id_empresa;
      this.fecha_ingreso = settings.metodos.formatoFecha(item.fecha_ingreso);
      this.id_tipo_doc_fiscal = item.id_tipo_doc_fiscal;
      this.codigo = item.codigo;
      this.nombre = item.nombre;
      this.razon_social = item.razon_social;
      this.id_departamento = item.id_departamento;
      this.id_municipio = item.id_municipio;
      this.direccion = item.direccion;
      this.id_actividad_economica = item.id_actividad_economica;
      this.tamaño = item.tamaño;
      this.registro = item.registro;
      this.email_1 = item.correo_1;
      this.email_2 = item.correo_2;
      this.contacto = item.contacto;
      this.telefono = item.telefono;
      this.tipo_persona = item.tipo_persona;
      this.id_identificador = item.id_identificador;
      this.documento = item.documento;
      this.con_retencion = item.con_retencion;
      this.con_percepcion = item.con_percepcion;
      this.cliente_activo = item.cliente_activo;
      this.cliente_x = item.cliente_x;
      this.exento = item.exento;
      this.con_credito = item.con_credito;
      this.exede_credito = item.exede_credito;
      this.limite_credito = item.limite_credito;
      this.maximo_dias_limite = item.max_dias_limite;
      this.extranjero = item.extranjero;
      this.id_tipo_exportacion = item.id_tipo_exportacion;
      this.es_zona_franca_dpa = item.zona_franca_dpa;
      this.id_pais = item.id_pais;

      this.listarMunicipios(item.id_departamento);
      this.listarPaises("edit");

      this.flag_nuevo_edit = 2;
      this.dialog = true;
    },
    actualizarCliente() {
      if (this.validarFormulario()) {
        axios
          .put("api/Clientes/Actualizar", {
            id_cliente: this.id_cliente,
            id_tipo_doc_fiscal: this.id_tipo_doc_fiscal,
            id_departamento: this.id_departamento,
            id_municipio: this.id_municipio,
            id_identificador: this.id_identificador,
            id_pais: this.id_pais,
            id_tipo_exportacion: this.id_tipo_exportacion,
            id_empresa: this.id_empresa,
            fecha_ingreso: this.fecha_ingreso,
            codigo: this.codigo,
            nombre: this.nombre,
            razon_social: this.razon_social,
            direccion: this.direccion,
            id_actividad_economica: this.id_actividad_economica,
            tamaño: this.tamaño,
            registro: this.registro,
            correo_1: this.email_1,
            correo_2: this.email_2,
            contacto: this.contacto,
            telefono: this.telefono,
            tipo_persona: this.tipo_persona,
            documento: this.documento,
            con_retencion: this.con_retencion,
            con_percepcion: this.con_percepcion,
            cliente_activo: this.cliente_activo,
            cliente_x: this.cliente_x,
            exento: this.exento,
            con_credito: this.con_credito,
            exede_credito: this.exede_credito,
            limite_credito: this.limite_credito,
            max_dias_limite: this.maximo_dias_limite,
            extranjero: this.extranjero,
            zona_franca_dpa: this.es_zona_franca_dpa,
          })
          .then(() => {
            this.snackbar_text = "Registro actualizado.";
            this.snackbar = true;
            this.cerrarDialogCliente();
            this.listarClientes();
          })
          .catch(() => {
            this.snackbar_text = "No se pudo actualizar.";
            this.snackbar = true;
          });
      }
    },
    cerrarDialogCliente() {
      this.dialog = false;
      this.limpiarCliente();
    },
    limpiarCliente() {
      this.fecha_ingreso = this.fecha_ingreso;
      this.id_tipo_doc_fiscal = "";
      this.codigo = "";
      this.nombre = "";
      this.razon_social = "";
      this.id_departamento = "";
      this.id_municipio = "";
      this.direccion = "";
      this.id_actividad_economica = "";
      this.tamaño = "";
      this.registro = "";
      this.email_1 = "";
      this.email_2 = "";
      this.contacto = "";
      this.telefono = "";
      this.tipo_persona = "";
      this.id_identificador = "";
      this.documento = "";
      this.con_retencion = false;
      this.cliente_activo = true;
      this.cliente_x = false;
      this.exento = false;
      this.con_percepcion = "";
      this.con_credito = false;
      this.exede_credito = false;
      this.limite_credito = "";
      this.maximo_dias_limite = "";
      this.extranjero = false;
      this.id_tipo_exportacion = "";
      this.es_zona_franca_dpa = false;
      this.id_pais = "";
      this.id_empresa = 0;

      //errores
      this.errorCodigo = false;
      this.errorNombre = false;
      this.errorTipoDocFiscal = false;
      this.errorRazonSocial = false;
      this.errorDepartamento = false;
      this.errorMunicipio = false;
      this.errorDireccion = false;
      this.errorActividadEconomica = false;
      this.errorTamaño = false;
      this.errorTelefono = false;
      this.errorTipoPersona = false;
      this.errorIdentificador = false;
      this.errorDocumento = false;
      this.errorPais = false;
      this.errorTipoExportacion = false;
    },
    async listarDepartamentos() {
      this.items_departamento = await (
        await settings.metodos.Departamentos()
      ).data;
    },
    async listarMunicipios(item) {
      var departamento = await (await settings.metodos.Departamento(item)).data;

      this.items_municipio = await (
        await settings.metodos.Municipios(departamento.cod_depa)
      ).data;
    },
    async listarPaises(status) {
      this.items_paises = await (await settings.metodos.Paises()).data;
    },
    setPais(event, { item }) {
      this.id_pais = item.id_pais;
      this.dialog_paises = false;
    },
    async listarIdentificadores() {
      this.items_identificador = await (
        await settings.metodos.Identificador()
      ).data;
    },
    async listarTipoDocFiscales() {
      this.items_tipo_doc_fiscal = await (
        await settings.metodos.TipoDocFiscal()
      ).data;
    },
    async listarTipoExportacion() {
      this.items_tipo_export = await (
        await settings.metodos.TipoExportacion()
      ).data;
    },
    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-dd");
    },
    estadoRetencion() {
      this.con_percepcion = false;
    },
    estadoPercepcion() {
      this.con_retencion = false;
    },
    seleccionExtranjero() {
      if (this.extranjero) {
        this.con_retencion = false;
        this.con_percepcion = false;
        this.exento = false;
      }
    },
    seleccionExento() {
      if (this.extranjero) {
        this.extranjero = false;
      }
    },
    validarFormulario() {
      let isValid = true;

      if (this.cliente_x && this.id_tipo_doc_fiscal === 11) {
        // Validación para id_tipo_doc_fiscal === 11
        this.errorCodigo = !this.codigo;
        this.errorNombre = !this.nombre;

        this.errorTipoDocFiscal = false;
        this.errorDepartamento = false;
        this.errorMunicipio = false;
        this.errorDireccion = false;
        this.errorActividadEconomica = false;
        this.errorTamaño = false;
        this.errorTelefono = false;
        this.errorTipoPersona = false;
        this.errorIdentificador = false;
        this.errorDocumento = false;
        this.errorPais = false;
        this.errorTipoExportacion = false;

      } else if (this.cliente_x && this.id_tipo_doc_fiscal === 10) {
        // Validación para id_tipo_doc_fiscal === 10
        this.errorPais = !this.id_pais;
        this.errorTipoPersona = !this.tipo_persona;
        //this.errorEmail2 = !this.email_2;

        this.errorTipoDocFiscal = false;

        this.errorDepartamento = false;
        this.errorRazonSocial = false;
        this.errorTipoPersona = false;
        this.errorPais = false;
        this.errorMunicipio = false;
        this.errorDireccion = false;
        this.errorActividadEconomica = false;
        this.errorTamaño = false;
        this.errorTelefono = false;
        this.errorEmail2 = false;
        this.errorIdentificador = false;
        this.errorDocumento = false;

        this.errorTipoExportacion = false;

      } else if (this.cliente_x && this.id_tipo_doc_fiscal === 12) {
        // Validación para id_tipo_doc_fiscal === 12 

        this.errorPais = !this.id_pais;
        this.errorTipoPersona = !this.tipo_persona;


        this.errorTipoDocFiscal = false;

        this.errorDepartamento = false;
        this.errorMunicipio = false;
        this.errorDireccion = false;
        this.errorActividadEconomica = false;
        this.errorTamaño = false;
        this.errorTelefono = false;
        this.errorEmail2 = false;
        this.errorIdentificador = false;
        this.errorDocumento = false;
        this.errorTipoExportacion = false;

      } else {
        // Validación general
        this.errorTipoDocFiscal = this.id_tipo_doc_fiscal === null;
        this.errorDepartamento = this.id_departamento === null;
        this.errorMunicipio = this.id_municipio === null;
        this.errorDireccion = !this.direccion;
        this.errorActividadEconomica = this.id_actividad_economica === null;
        this.errorTamaño = !this.tamaño;
        this.errorTelefono = !this.telefono;
        this.errorTipoPersona = this.tipo_persona === null;
        this.errorIdentificador = this.id_identificador === null;
        this.errorDocumento = !this.documento;
        this.errorPais = this.id_pais === null && this.extranjero;
        this.errorTipoExportacion = this.id_tipo_exportacion === null && this.extranjero;
        this.errorCodigo = !this.codigo;
        this.errorNombre = !this.nombre;

      }

      // Verificación de errores
      if (
        this.errorTipoDocFiscal ||
        this.errorCodigo ||
        this.errorNombre ||
        this.errorDepartamento ||
        this.errorMunicipio ||
        this.errorDireccion ||
        this.errorActividadEconomica ||
        this.errorTamaño ||
        this.errorTelefono ||
        this.errorTipoPersona ||
        this.errorIdentificador ||
        this.errorDocumento ||
        this.errorPais ||
        this.errorTipoExportacion ||
        this.errorEmail2
      ) {
        isValid = false;
      }

      return isValid;
    },


    eliminarCliente() {
      this.dialog_eliminar_cliente = true;
    },
    confirmarEliminarCliente() {
      axios
        .delete("api/Clientes/Eliminar/" + this.id_cliente)
        .then((response) => {
          if (response.data == 400) {
            this.snackbar_text =
              "Este cliente no se puede eliminar, existen movimientos relaciones";
            this.snackbar = true;
            return;
          }
          this.dialog_eliminar_cliente = false;
          this.dialog = false;
          this.listarClientes();
          this.snackbar_text = "Cliente eliminado";
          this.snackbar = true;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo eliminar el cliente";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
.borde {
  border: 1px solid black;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>