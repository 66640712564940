<template>
  <v-container fluid>
    <CompraSwisstex v-if="$store.state.emp.cod_empresa == 381" />
    <CompraConab v-if="$store.state.emp.cod_empresa == 383" />
    <CompraIBM v-if="$store.state.emp.cod_empresa == 469" />
    <CompraTX v-if="$store.state.emp.cod_empresa == 445" />
    <CompraSperidian v-if="$store.state.emp.cod_empresa == 372" />
    <ComprasHYGGE v-if="$store.state.emp.cod_empresa == 460" />
    <ComprasUnique v-if="$store.state.emp.cod_empresa == 380" />
    <CompraNaka v-if="$store.state.emp.cod_empresa == 466" />


    <div v-if="
      $store.state.emp.cod_empresa != 372 &&
      $store.state.emp.cod_empresa != 381 &&
      $store.state.emp.cod_empresa != 383 &&
      $store.state.emp.cod_empresa != 469 &&
      $store.state.emp.cod_empresa != 445 &&
      $store.state.emp.cod_empresa != 460 &&
      $store.state.emp.cod_empresa != 380 &&
      $store.state.emp.cod_empresa != 466
    ">
      <lottie :options="defaultOptions" :height="350" :width="350" v-on:animCreated="handleAnimation" />
    </div>

  </v-container>
</template>

<script>
import CompraConab from "./Conab/CompraConab.vue";
import CompraSwisstex from "./Swisstex/CompraSwisstex.vue";
import CompraIBM from "./IBMaker/CompraIBM.vue";
import CompraTX from "./TXOOL/CompraTX.vue";
import CompraSperidian from "./Speridian/CompraSperidian.vue";
import ComprasHYGGE from "./HYGGE/ComprasHYGGE.vue";
import ComprasUnique from "./Unique/UniqueC.vue";
import CompraNaka from "./Naka/CompraNaka.vue";

import Lottie from "vue-lottie";
import * as animationData from "../../animations/no_datta.json";


export default {
  data: () => ({
    defaultOptions: { animationData: animationData },
    animationSpeed: 1,
  }),
  components: {
    Lottie,
    CompraSwisstex,
    CompraConab,
    CompraIBM,
    CompraTX,
    CompraSperidian,
    ComprasHYGGE,
    ComprasUnique,
    CompraNaka
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
};
</script>