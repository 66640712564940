<template>
    <v-container fluid>
      <v-data-table :headers="headers_compras" :items="items_compras" :loading="loading_compras">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Compras</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="nuevaCompra()"> Nuevo </v-btn>
          </v-toolbar>
        </template>
  
        <template v-slot:[`item.fecha`]="{ item }">
          <span> {{ formatoFecha(item.fecha) }}</span>
        </template>
  
        <template v-slot:[`item.action_edit`]="{ item }">
          <v-icon @click="editarCompra(item)"> fa-solid fa-pen-to-square </v-icon>
        </template>
  
        <template v-slot:[`item.action_send_dte`]="{ item }">
          <v-icon @click="enviarDTE(item)" :disabled="item.resolucion != ''">
            fa-regular fa-paper-plane
          </v-icon>
        </template>
  
        <template v-slot:[`item.action_gen_preview`]="{ item }">
          <v-icon @click="vistaPreviaPdf(item)" :disabled="item.resolucion != ''" :loading="loading"> fa-regular
            fa-file-pdf </v-icon>
        </template>
  
        <template v-slot:[`item.action_view_dte`]="{ item }">
          <v-icon @click="vistaPreviaPdfSello(item)" :disabled="item.resolucion == ''">
            fa-regular fa-file-pdf
          </v-icon>
        </template>
  
        <template v-slot:[`item.estatus`]="{ item }">
          <v-tooltip left color="primary" v-if="
            item.resolucion == '' && item.resolucion_invalidacion == null
          ">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">fa-regular fa-floppy-disk</v-icon>
            </template>
            <span>GUARDADO</span>
          </v-tooltip>
  
          <v-tooltip left color="success" v-else-if="
            item.resolucion != '' && item.resolucion_invalidacion == null
          "> 
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="success" v-bind="attrs" v-on="on">fa-regular fa-circle-check</v-icon>
            </template>
            <span>PROCESADO</span>
          </v-tooltip>
  
          <v-tooltip left color="error" v-else-if="
            item.resolucion_invalidacion != '' &&
            item.resolucion_invalidacion != null
          ">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on">fa-solid fa-ban</v-icon>
            </template>
            <span>ANULADO</span>
          </v-tooltip>
  
          <v-tooltip left color="error" v-else-if="item.estatus == 'ERROR'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on">fa-solid fa-circle-exclamation</v-icon>
            </template>
            <span>ERROR</span>
          </v-tooltip>
        </template>
      </v-data-table>
  
      <v-dialog v-model="dialog_compras" width="3000" scrollable persistent>
        <v-card color="grey">
          <v-card-text class="px-0">
            <!-- CABECERA -->
            <v-card>
              <v-card-text>
                <v-row no-gutters>
  
                  <v-col cols="6" md="2" class="pa-17">
                    <v-text-field v-model="nombre_usuario" label="Usuario" outlined dense hide-details
                      readonly></v-text-field>
                  </v-col>
  
                  <v-spacer></v-spacer>
  
                  <v-col cols="12" md="6" class="pa-1"></v-col>
  
                  <v-col cols="12" md="2" class="pa-1">
                    <v-text-field v-model="rubro" label="Rubro Empresa" outlined dense hide-details
                      readonly></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete v-model="sucursal" :items="items_sucursales" item-text="nombre"
                      item-value="id_sucursal" label="Sucursal" outlined dense hide-details :error="errorSucursal"
                      @input="listarTipoDocs()">
                      <template v-slot:selection="data">
                        {{ data.item.cod_sucursal }} {{ data.item.nombre }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.cod_sucursal }} {{ data.item.nombre }}
                      </template>
                    </v-autocomplete>
                  </v-col>
  
                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete v-model="tipo_doc_fiscal" :items="items_tipo_doc_fiscales"
                      item-text="nombre_doc_fiscal" item-value="id_tipo_doc_fiscal" label="Tipo Documento" outlined dense
                      hide-details return-object @change="listarTipoDocs()" :error="errorDocumentoIdentificador"
                      @input="listarDocSeleccionado()">
                      <template v-slot:selection="data">
                        {{ data.item.cod_doc_fiscal }} {{ data.item.nombre }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.cod_doc_fiscal }} {{ data.item.nombre }}
                      </template>
                    </v-autocomplete>
                  </v-col>
  
                  <template v-slot:[`item.fecha`]="{ item }">
                    <span> {{ formatoFecha(item.fecha) }}</span>
                  </template>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field v-model="fecha" label="Fecha y hora" type="datetime-local" outlined dense
                      hide-details></v-text-field>
                  </v-col>
  
  
                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete label="Condición pago" v-model="cond_pago" :items="items_cond_pago" item-text="cod"
                      item-value="cod" :error="errorCondicionPago" outlined dense hide-details>
                      <template v-slot:selection="data">
                        {{ data.item.cod }} {{ data.item.nombre }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.cod }} {{ data.item.nombre }}
                      </template>
                    </v-autocomplete>
                  </v-col>
  
                  <v-col cols="12" md="6" class="pa-1">
  
                    <v-text-field v-model="documento" label="Número de Control" outlined dense hide-details
                      readonly></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field label="Código de Generación" v-model="serie" outlined dense hide-details
                      :error="errorCodigoG" readonly></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field v-model="resolucion" label="Resolucion" outlined dense hide-details
                      readonly></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field v-model="fecha_recepcion" label="Fecha de recepción" type="datetime-local" outlined
                      dense hide-details readonly></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="2" class="pa-1">
                    <v-checkbox label="Con Renta 10%" v-model="con_renta" @change="calculoRetencionRenta()" dense
                      hide-details></v-checkbox>
                  </v-col>
  
                  <v-col cols="12" md="2" class="pa-1">
                    <v-checkbox label="Emitir como anulado" v-model="emitir_anulado" dense hide-details></v-checkbox>
                  </v-col>
  
                </v-row>
              </v-card-text>
            </v-card>
  
            <!-- DETALLES PROVEEDOR -->
            <v-card class="mt-3">
              <v-card-title>PROVEEDORES</v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field v-model="cod_prov" label="Código" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="auto" class="pa-1">
                    <v-btn color="primary" @click="listarProveedores()">
                      <v-icon>fas fa-search</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pa-1">
                    <v-btn color="success" @click="dialogNuevoProveedor()">
                      <v-icon>fas fa-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pa-1">
                    <v-btn color="grey" @click="dialogEditarProveedor()" :disabled="id_proveedor == 0">
                      <v-icon color="white">fas fa-edit</v-icon>
                    </v-btn>
                  </v-col>
  
                  <v-spacer></v-spacer>
  
                  
  
                  <v-col cols="12" class="pa-1">
                    <v-text-field v-model="nombre_prov" label="Nombre" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field v-model="receptor_prov" label="Receptor" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field v-model="tamaño_prov" label="Tamaño" outlined dense hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field v-model="registro_prov" label="Registro" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
  
            <!-- GASTOS -->
            <v-card class="mt-3">
              <v-card-title>GASTOS</v-card-title>
              <v-card-text>
  
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-checkbox label="Seleccionar Manualmente" v-model="selec_manual" @change="modoManual(selec_manual)"
                      dense hide-details></v-checkbox>
                  </v-col>
  
                  <v-col cols="12" md="12" class="pa-1">
                  </v-col>
  
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete v-model="tipo_operacion" :items="items_tipo_operacion" item-text="tipo_operacion"
                      item-value="id_tipo_operacion" label="Tipo Operación" outlined dense hide-details
                      :error="errorSucursal" @change="actualizarCostoGastos" >
                      <template v-slot:selection="data">
                        {{ data.item.codigo }} {{ data.item.tipo_operacion }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.codigo }} {{ data.item.tipo_operacion }}
                      </template>
                    </v-autocomplete>
                  </v-col>
  
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete v-model="clasificacion" :items="items_clasificacion" item-text="clasificacion"
                      item-value="id_clasificacion" label="Clasificación" outlined dense hide-details
                      :error="errorSucursal" @change="actualizarCostoGastos">
                      <template v-slot:selection="data">
                        {{ data.item.codigo }} {{ data.item.clasificacion }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.codigo }} {{ data.item.clasificacion }}
                      </template>
                    </v-autocomplete>
                  </v-col>
  
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete v-model="costo_gastos" :items="items_costogasto" item-text="costo_gasto"
                      item-value="id_costo_gasto" label="Tipo de Costo/Gasto" outlined dense hide-details>
                      <template v-slot:selection="data">
                        {{ data.item.codigo }} {{ data.item.costo_gasto }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.codigo }} {{ data.item.costo_gasto }}
                      </template>
                    </v-autocomplete> 
                  </v-col>
  
                  <v-col cols="12" md="2" align-self="center">
                    <v-btn block :disabled="tipo_doc_fiscal.cod_doc_fiscal != 'NCR' &&
                      tipo_doc_fiscal.cod_doc_fiscal != 'NDB' &&
                      tipo_doc_fiscal.cod_doc_fiscal != 'CCF' &&
                      tipo_doc_fiscal.cod_doc_fiscal != 'FAC' &&
                      tipo_doc_fiscal.cod_doc_fiscal != 'NRM'" @click="dialogIngresoAutomaticoDTE = true">
                      <v-icon left>fa-solid fa-file-import</v-icon>
                      Ingreso Automático de DTE
                    </v-btn>
  
                  </v-col>
  
  
  
                </v-row>
              </v-card-text>
            </v-card>
  
            <v-dialog v-model="dialogIngresoAutomaticoDTE" width="1000">
              <v-card>
                <v-card-title>Importar Documento Tributario Electrónico</v-card-title>
                <v-card-text>
  
                  <v-row>
  
                    <v-col cols="12" md="12">
                      <v-card outlined>
                        <v-card-title>DESDE DATOS</v-card-title>
                        <v-row no-gutters>
                          <v-col cols="12" md="5" class="pa-2">
                            <v-text-field label="Fecha" v-model="fecha_dte" type="datetime-local" outlined dense
                              hide-details></v-text-field>
                          </v-col>
  
                          <v-col cols="12" md="7" class="pa-2">
                            <v-text-field label="Codigo de Generación" v-model="codigo_generacion" outlined dense
                              hide-details></v-text-field>
                          </v-col>
  
                          <v-col cols="12" md="5" class="pa-2"></v-col>
  
                          <v-col cols="12" md="8" class="pa-1">
                            <v-checkbox label="Crear Proveedor si no existe" v-model="proveedor_dte" dense
                              hide-details></v-checkbox>
                          </v-col>
  
                          <v-col cols="12" md="4" class="pa-1"></v-col>
  
                          <v-col cols="12" md="2" class="pa-1"></v-col>
  
                          <v-col cols="auto" class="pa-1">
                            <v-btn>
                              <v-icon left>fa-solid fa-file-circle-plus</v-icon>
                              Validar Documento MH
                            </v-btn>
                          </v-col>
  
  
                          <v-col cols="auto" class="pa-1">
                            <v-btn>
                              <v-icon left>fa-solid fa-file-circle-plus</v-icon>
                              Ingresar Documento
                            </v-btn>
                          </v-col>
  
                          <v-col cols="auto" class="pa-1">
                            <v-btn>
                              <v-icon left>fa-solid fa-copy</v-icon>
                              Copiar Datos
                            </v-btn>
                          </v-col>
  
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogIngresoAutomaticoDTE = false">Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
  
  
  
            <!-- TABLA DE SERVICIOS -->
            <v-card class="mt-3">
              <v-card-title>SERVICIOS</v-card-title>
              <v-card-text>
                <v-data-table :headers="headers_productos" :items="items_productos">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row no-gutters justify="center" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="12" md="auto" align-self="center">
                        </v-col>
  
                        <v-col cols="12" md="1">
                          <v-text-field v-model="cantidad_prod" label="Cantidad" outlined dense hide-details class="mx-1"
                            @input="calculoTotal"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                          <v-text-field v-model="cod_prod" label="Código" outlined dense hide-details class="mx-1"
                            :error="errorCodigo"></v-text-field>
                        </v-col>
  
                        <v-btn outlined @click="listarProductos()">
                          <v-icon> fa-solid fa-magnifying-glass </v-icon>
                        </v-btn>
  
                        <v-col cols="12" md="5">
                          <v-text-field v-model="nombre_prod" label="Servicio General" outlined dense hide-details
                            class="mx-1"></v-text-field>
                        </v-col>
  
                        <v-col cols="12" md="1">
                          <v-text-field v-model="precio" label="Precio Unitario" type="number" outlined dense hide-details
                            class="mx-1" @input="calculoTotal"></v-text-field>
                        </v-col>
  
                        <v-col cols="12" md="1">
                          <v-text-field v-model="descuento_servicio" label="%Descuento" type="number"
                            @change="calculoDescuento()" outlined dense hide-details class="mx-1">
                          </v-text-field>
                        </v-col>
  
  
                        <v-col cols="12" md="1">
                          <v-text-field v-model="total_prod" label="Total" outlined dense hide-details
                            class="mx-1"></v-text-field>
                        </v-col>
  
                        <v-col cols="12" md="1" align-self="center">
                          <v-btn @click="agregarProducto()">
                            <v-icon color="green">
                              fa-solid fa-square-check
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row no-gutters v-else>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark v-bind="attrs" v-on="on">
                              Opciones
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-row no-gutters>
                                <v-col cols="12" align-self="center" class="ma-1">
                                  <v-btn outlined block @click="listarProductos()">
                                    <v-icon> fa-solid fa-magnifying-glass </v-icon>
                                  </v-btn>
                                </v-col>
                                <v-col cols="12" class="ma-1">
                                  <v-text-field v-model="cantidad_prod" label="Cantidad" outlined dense hide-details
                                    @input="calculoTotal"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="ma-1">
                                  <v-text-field v-model="cod_prod" label="Código" outlined dense
                                    hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" class="ma-1">
                                  <v-text-field v-model="nombre_prod" label="Nombre" outlined dense
                                    hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" class="ma-1">
                                  <v-text-field v-model="precio" label="Precio Unitario" outlined dense
                                    hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" class="ma-1">
                                  <v-text-field v-model="descuento_servicio" label="$Descuento" type="number"
                                    @change="calculoDescuento()" outlined dense hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" class="ma-1">
                                  <v-text-field v-model="total_prod" label="Total" outlined dense
                                    hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" class="ma-1" align-self="center">
                                  <v-btn @click="agregarProducto()" block>
                                    <v-icon color="green">
                                      fa-solid fa-square-check
                                    </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-row>
                    </v-toolbar>
                  </template>
  
                  <template v-slot:[`item.action_edit`]="{ item }">
                    <v-icon @click="editarProducto(item)">
                      fa-solid fa-pen-to-square
                    </v-icon>
                  </template>
  
                  <template v-slot:[`item.action_delete`]="{ item }">
                    <v-icon @click="removerProducto(item)">
                      fa-solid fa-trash-can
                    </v-icon>
                  </template>
  
                  <template v-slot:[`item.descuento`]="{ item }">
                    <span> % {{ Number(item.descuento).toFixed(2) }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
  
            <!-- CALCULOS -->
            <v-card class="mt-3">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>COMPRAS GRAVADAS</v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="Internas/Importaciones" v-model="internas_importaciones"
                            prepend-inner-icon="fa-solid fa-dollar-sign" :error="errorInternas_Importaciones" outlined
                            dense hide-details></v-text-field>
                        </v-col>
  
                      </v-row>
                    </v-card>
                  </v-col>
  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>RENTA</v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="Renta 10%" v-model="renta" prepend-inner-icon="fa-solid fa-dollar-sign"
                            outlined dense hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
  
  
  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>NO SUJETO </v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="No Sujeto" v-model="no_sujeto" prepend-inner-icon="fa-solid fa-dollar-sign"
                            outlined dense hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>COMPRAS EXENTAS</v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="Internas/Importaciones" v-model="internas_importaciones_exentas"
                            prepend-inner-icon="fa-solid fa-dollar-sign" :error="errorInternas_Importaciones" outlined
                            dense hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>A-TERCEROS-IVA</v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="IVA" v-model="iva" prepend-inner-icon="fa-solid fa-dollar-sign" outlined
                            dense hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
  
  
  
  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="Otros Cargos" v-model="otros_cargos"
                            prepend-inner-icon="fa-solid fa-dollar-sign" outlined dense hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="Descuento" v-model="descuento" prepend-inner-icon="fa-solid fa-dollar-sign"
                            outlined dense hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>IMPUESTOS COMBUSTIBLES</v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="FOVIAL" v-model="fovial" prepend-inner-icon="fa-solid fa-dollar-sign"
                            outlined dense hide-details @input="calcularTotales"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="COTRANS" v-model="cotrans" prepend-inner-icon="fa-solid fa-dollar-sign"
                            outlined dense hide-details @input="calcularTotales"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>RESUMEN DE OPERACIONES</v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="Total operación" v-model="total_operacion"
                            prepend-inner-icon="fa-solid fa-dollar-sign" :error="errorTotal_Operacion" outlined dense
                            hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-2">
                          <v-text-field label="Total a pagar" v-model="total_pagar"
                            prepend-inner-icon="fa-solid fa-dollar-sign" :error="errorTotal_Pagar" outlined dense
                            hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions class="white">
            <v-btn color="primary" @click="crearCompra()" v-if="flag == 1" :loading="loading">Guardar</v-btn>
            <v-btn color="primary" @click="actualizarCompra()" v-if="flag == 2" :disabled="resolucion != ''"
              :loading="loading">Actualizar</v-btn>
  
            <v-btn color="red" @click="confirmarAnularDocumento()" v-if="
              (flag == 2 &&
                $store.state.usuario.rol == 'Administrador') ||
              $store.state.usuario.rol == 'IT'
            " :disabled="resolucion == ''">Anular</v-btn>
  
            <v-spacer></v-spacer>
            <v-btn text @click="cerrarDialogCompras()">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_proveedores" width="1000">
        <v-data-table :headers="headers_proveedores" :items="items_proveedores" @dblclick:row="setProveedor">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Proveedores</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <div style="color: black">[Hacer doble click para seleccionar]</div>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-dialog>
  
      <v-dialog v-model="dialog_productos" width="1000">
        <v-data-table :headers="headers_productos_list" :items="items_productos_list" @dblclick:row="setProductos">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Productos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <div style="color: black">[Hacer doble click para seleccionar]</div>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-dialog>
  
      <v-dialog v-model="dialog_eliminar_producto_detalle" width="500">
        <v-card>
          <v-card-title> ¿Esta seguro de eliminar este producto? </v-card-title>
          <v-card-actions>
            <v-btn color="red" dark @click="eliminarProductoDetalle()">Eliminar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog_eliminar_producto_detalle = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_confirmar_anular" width="500">
        <v-card>
          <v-card-title>¿Esta seguro de anular el documento?</v-card-title>
          <v-card-actions>
            <v-btn color="red" @click="anularFactura()">Confirmar</v-btn>
  
            <v-spacer></v-spacer>
            <v-btn @click="dialog_confirmar_anular = !dialog_confirmar_anular">Cancelar</v-btn>
            <v-spacer></v-spacer>
  
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_preview_report" fullscreen persistent>
        <v-toolbar flat dense>
          <v-toolbar-title> DOCUMENTO </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="dialog_preview_report = !dialog_preview_report">far fa-times-circle</v-icon>
        </v-toolbar>
        <iframe :src="base64_doc" id="myPDF" frameborder="0" />
      </v-dialog>
  
      <ProveedoresCrearEditar :estado_dialog.sync="dialog_crear_editar_proveedor"
        :estado_nuevo_editar_dialog.sync="estado_crear_editar_proveedor"
        :errorDocumentoIdentificador="errorDocumentoIdentificador" />
  
      <v-snackbar v-model="snackbar" timeout="4000" color="blue" multi-line elevation="24" outlined top right>
        <v-icon>fa-solid fa-circle-info</v-icon>
        {{ snackbar_text }}
      </v-snackbar>
    </v-container>
  </template>
  
  <script>
  import axios from "axios";
  import settings from "@/settings/settings";
  import ProveedoresCrearEditar from "@/views/Proveedor_Crear_Editar.vue";
  
  
  export default {
    data() {
      return {
        flag: 1,
        headers_compras: [
          { text: "Fecha", value: "fecha" },
          { text: "Documento", value: "documento" },
          { text: "Tipo Doc", value: "cod_doc_fiscal" },
          { text: "Proveedor", value: "nom_proveedor" },
          { text: "Estatus", value: "estatus", align: "center" },
          {
            text: "Enviar DTE",
            value: "action_send_dte",
            align: "center",
            sortable: false,
          },
          {
            text: "Ver previa",
            value: "action_gen_preview",
            align: "center",
            sortable: false,
          },
          {
            text: "Ver DTE",
            value: "action_view_dte",
            align: "center",
            sortable: false,
          },
          { text: "Editar", value: "action_edit", align: "center" },
  
        ],
  
  
        items_compras: [],
        snackbar: false,
        snackbar_text: "",
        dialog_compras: false,
        id_compra: 0,
        id_empresa: 0,
        items_tipo_doc_fiscales: [],
        tipo_doc_fiscal: "",
        id_tipo_doc_fiscal: 0,
        items_cond_pago: [
          { cod: "01", nombre: "CONTADO" },
          { cod: "02", nombre: "CREDITO" },
        ],
        cond_pago: "",
        items_sucursales: [],
  
        items_tipo_operacion: [],
        items_clasificacion: [],
        items_costogasto: [],
  
  
        items_usuario: [],
        items_rubro: [],
        id_sucursal: 0,
        id_usuario: 0,
        nombre_usuario: "",
        fecha: "",
        documento: "DTE-00-00000000-000000000000000",
        comp_retencion: "",
        con_renta: true,
        serie: "",
        resolucion: "",
        fecha_recepcion: "",
        emitir_anulado: false,
        num_poliza: "",
        quedan: "",
  
        errorDocumentoIdentificador: false,
  
        internas_importaciones: 0,
        iva: 0,
        renta: 0,
        total_operacion: 0,
        total_pagar: 0,
        //PROVEEDORES
        dialog_proveedores: false,
        headers_proveedores: [
          { text: "Código", value: "codigo" },
          { text: "Nombre", value: "nombre" },
          { text: "Dirección", value: "direccion" },
          { text: "Teléfono", value: "telefono" },
          { text: "Registro", value: "registro" },
          { text: "Documento", value: "documento_identificador" },
        ],
        items_proveedores: [],
        id_proveedor: 0,
        cod_prov: "",
        con_retencion_prov: false,
        sucursal: "",
        tipo_doc_fiscal: "",
        con_percepcion_prov: false,
        nombre_prov: "",
        receptor_prov: "",
        tamaño_prov: "",
        registro_prov: "",
  
        //PRODUCTOS
        headers_productos: [
          { text: "Cantidad", value: "cantidad_prod" },
          { text: "Código", value: "cod_producto" },
          { text: "Nombre", value: "nom_producto" },
          { text: "Precio Unitario", value: "precio" },
          { text: "Descuento", value: "descuento_servicio" },
          { text: "Total", value: "total_prod" },
          { text: "Editar", value: "action_edit", align: "center" },
          { text: "Eliminar", value: "action_delete", align: "center" },
        ],
        items_productos: [],
        dialog_productos: false,
        headers_productos_list: [
          { text: "Código", value: "cod_producto" },
          { text: "Nombre", value: "nom_producto" },
          { text: "Precio", value: "precio" },
        ],
        items_productos_list: [],
  
        cod_prod: "",
        nombre_prod: "",
        precio: 0,
        cantidad_prod: 1,
        total_prod: 0,
  
        //DETALLE PRODUCTO
        id_detalle_compra: 0,
        dialog_eliminar_producto_detalle: false,
        dialog_crear_editar_proveedor: false,
        estado_crear_editar_proveedor: "",
  
        loading: false,
        loading_compras: false,
  
        base64_doc: "",
        dialog_confirmar_anular: false,
        dialog_preview_report: false,
  
        //Validaciones
  
        errorFecha: false,
        errorDocumentoIdentificador: false,
        errorSucursal: false,
        errorCondicionPago: false,
        errorInternas_Importaciones: false,
        errorTotal_Operacion: false,
        errorTotal_Pagar: false,
        errorCodigoG: false,
        errorCodigo: false,
  
  
        //Nuevos
  
        rubro: "",
        selec_manual: false,
        tipo_operacion: "",
        clasificacion: "",
        costo_gastos: "",
  
        descuento_servicio: 0,
  
        fovial: 0.00,
        cotrans: 0.00,
        gravadas: 0.00,
        exentas: 0.00,
        descuentos: 0.00,
        no_sujeto: 0.00,
        otros_cargos: 0.00,
        retencion: 0.00,
        percepcion: 0.00,
        anticipo: 0.00,
        importaciones: 0,
  
        internas_exentas: 0.00,
        internas_importaciones_exentas: 0.00,
  
        monto_descuento: 0,
        descuento: 0,
  
        s_percepcion: false,
        s_retencion: false,
  
        flag_descuento: false,
  
        dialogIngresoAutomaticoDTE: false,
  
        fecha_dte: "",
        codigo_generacion: "",
        proveedor_dte: false,
  
      };
    },
  
    components: {
      ProveedoresCrearEditar,
    },
  
    created() {
  
      this.listarUsuarios();
      this.listarRubro();
  
  
      if (this.$store.state.emp.id_empresa) {
        this.inicializarDatos();
      } else {
  
      };
    },
  
    watch: {
      "$store.state.emp.id_empresa": function (newVal, oldVal) {
        if (newVal) {
          this.inicializarDatos();
        }
      },
      "$store.state.usuario.id_usuario": function (newVal, oldVal) {
        if (newVal) {
          this.inicializarDatos();
        }
      },
      cantidad_prod(newVal) {
        this.calculoTotal();
        this.calcularTotales();
      },
      precio(newVal) {
        this.calculoTotal();
        this.calcularTotales();
      },
  
      descuento_servicio(newVal) {
        this.calculoDescuento();
        this.calcularTotales();
      },
      internas_importaciones(newVal) {
        this.calcularTotales();
      },
      iva(newVal) {
        this.calcularTotales();
      },
  
      tipo_operacion(newVal) {
        this.actualizarCostoGastos(); 
        this.calcularTotales(); 
      },
      clasificacion(newVal) {
        this.actualizarCostoGastos(); 
        this.calcularTotales();
      },
      costo_gastos(newVal) {
        this.calcularTotales();
      },
      selec_manual(newVal) {
        this.modoManual(newVal); // Activa el modo manual si es seleccionado
      }
  
    },
  
    methods: {
      inicializarDatos() {
        this.listarCompras();
        this.listarSucursales();
        this.listarTipoOperacion();
        this.listarClasificacion();
        this.listarCostoGasto();
      },
  
  
      dialogNuevoProveedor() {
        this.dialog_crear_editar_proveedor = true;
        this.estado_crear_editar_proveedor = 0;
      },
  
      dialogEditarProveedor() {
        this.dialog_crear_editar_proveedor = true;
        this.estado_crear_editar_proveedor = this.id_proveedor;
      },
  
      listarCompras() {
        if (this.$store.state.emp.id_empresa) {
          this.loading_compras = true;
          axios.get("api/Compras/Listar/" + this.$store.state.emp.id_empresa)
            .then((response) => {
              if (Array.isArray(response.data)) {
                this.items_compras = response.data;
              } else {
                console.error("Respuesta no esperada:", response.data);
                this.items_compras = [];
  
                this.snackbar = true;
              }
              this.loading_compras = false;
            })
            .catch((error) => {
              this.loading_compras = false;
              console.error("Error al listar las compras:", error);
              this.snackbar_text = "No se pudieron cargar las compras";
              this.snackbar = true;
            });
        } else {
          console.error("El id_empresa es undefined");
          this.snackbar_text = "El id_empresa no está definido";
          this.snackbar = true;
        }
      },
  
      listarDetallesCompras(id_compra) {
        axios.get("api/DetalleCompras/Listar/" + id_compra).then((response) => {
          if (Array.isArray(response.data)) {
            this.items_productos = response.data;
          } else {
            console.error("Respuesta no esperada:", response.data);
            this.items_productos = [];
  
            this.snackbar = true;
          }
  
          // Método para calcular el total
          this.calcularTotalInternasImportaciones();
  
        })
          .catch((error) => {
            console.error("Error al listar los detalles de la compra:", error);
            this.snackbar_text = "No se pudieron cargar los detalles de la compra";
            this.snackbar = true;
          });
      },
  
      listarUsuarios() {
        axios.get('api/Usuarios/Listar')
          .then(response => {
            this.items_usuario = response.data;
          })
          .catch(error => {
            console.error('Error al cargar usuarios:', error);
          });
      },

      listarRubro() {
      axios.get("api/Rubro/Listar")
        .then((response) => {
          
          if (Array.isArray(response.data)) {
            this.items_rubro = response.data;
          } else {
            console.error("La API no devolvió un array:", response.data);
            this.items_rubro = []; // Siempre sea un array
          }
        })
        .catch((error) => {
          console.error("Error al obtener rubros:", error);
          this.items_rubro = []; 
        });
    },
  
  
      nuevaCompra() {
        this.fecha = settings.metodos.formatoFechaHora(new Date());
        this.flag = 1;
        this.generarGuid();
  
        // Asigna el ID y el nombre del usuario 
        this.id_usuario = this.$store.state.usuario.idUsuario;
        this.nombre_usuario = this.$store.state.usuario.nombre;
  
        this.rubro = this.$store.state.emp.rubro;
  
        this.dialog_compras = true;
      },
  
  
      async listarTipoDocs() {
        this.items_tipo_doc = await (await settings.metodos.TipoDocFiscal()).data;
      },
  
  
  
      async listarSucursales() {
        this.items_sucursales = await (
          await settings.metodos.Sucursales(this.$store.state.emp.id_empresa)
        ).data;
      },
  
      listarProveedores() {
        axios
          .get("api/Proveedores/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            this.items_proveedores = response.data;
            this.dialog_proveedores = true;
          });
      },
  
      listarProductos() {
        axios
          .get("api/Productos/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            this.items_productos_list = response.data;
            this.dialog_productos = true;
          });
      },
  
      listarSucursales() {
        axios
          .get("api/Sucursales/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            this.items_sucursales = response.data;
            this.sucursal = response.data[0].id_sucursal;
            this.listarTipoDocs();
          });
      },
  
      //Nuevos
  
      listarTipoOperacion() {
        axios.get("api/TipoOperacion/Listar")
          .then((response) => {
            this.items_tipo_operacion = response.data;
          });
      },
      listarClasificacion() {
        axios.get("api/Clasificacion/Listar")
          .then((response) => {
            this.items_clasificacion = response.data;
          });
      },
      listarCostoGasto() {
        axios.get("api/CostoGasto/Listar")
          .then((response) => {
            this.items_costogasto = response.data;
          });
      },
      calcularTipoCostoGasto() {
        if (this.tipo_operacion && this.clasificacion) {
          const combinaciones = {
            '1-1': 4,
            '2-2': 1,
            '1-2': 1,
            '2-1': 4,
            '3-1': 4,
            '3-2': 1,
            '9-9': 9,
            '9-1': 9,
            '1-9': 9,
          };
  
          const key = `${this.tipo_operacion}-${this.clasificacion}`;
          console.log("Key:", key);
          console.log("Tipo de Costo/Gasto encontrado:", combinaciones[key]);
  
          this.costo_gastos = combinaciones[key] || 'Sin resultado';
        }
      },
  
      calcularTipoCostoGastoo() {
        if (this.clasificacion && this.tipo_operacion) {
          const combinaciones = {
            '1-1': 4,
            '2-2': 1,
            '1-2': 1,
            '2-1': 4,
            '3-1': 4,
            '3-2': 1,
            '9-9': 9,
            '9-1': 9,
            '1-9': 9,
          };
  
          const key = `${this.clasificacion}-${this.tipo_operacion}`;
          console.log("Key:", key);
          console.log("Tipo de Costo/Gasto encontrado:", combinaciones[key]);
  
          this.costo_gastos = combinaciones[key] || 'Sin resultado';
        }
      },
  
      actualizarCostoGastos() {
        if (this.tipo_operacion && this.clasificacion) {
          const combinaciones = {
            '1-1': 4,
            '2-2': 1,
            '1-2': 1,
            '2-1': 4,
            '3-1': 4,
            '3-2': 1,
            '9-9': 9,
            '9-1': 9,
            '1-9': 9,
          };
  
          const key = `${this.tipo_operacion}-${this.clasificacion}`;
          this.costo_gastos = combinaciones[key] || 'Sin resultado';
        }
      },
  
      actualizarCostoGastoos() {
        if (this.clasificacion && this.tipo_operacion) {
          const combinaciones = {
            '1-1': 4,
            '2-2': 1,
            '1-2': 1,
            '2-1': 4,
            '3-1': 4,
            '3-2': 1,
            '9-9': 9,
            '9-1': 9,
            '1-9': 9,
          };
  
          const key = `${this.clasificacion}-${this.tipo_operacion}`;
          this.costo_gastos = combinaciones[key] || 'Sin resultado';
        }
      },
  
      modoManual(newVal) {
        if (newVal) {
          this.tipo_operacion = null;
          this.clasificacion = null;
          this.costo_gastos = null;
        }
      },
  
  
  
      setProveedor(event, { item }) {
        this.id_proveedor = item.id_proveedor;
        this.cod_prov = item.codigo;
        this.con_percepcion_prov = item.s_percepcion;
        this.con_retencion_prov = item.s_retencion;
        this.nombre_prov = item.nombre;
        this.receptor_prov = item.codigo + "-" + item.documento_identificador;
        this.tamaño_prov = item.tamaño;
        this.registro_prov = item.registro;
        this.dialog_proveedores = false;
      },
  
      setProductos(event, { item }) {
  
        this.cod_prod = item.cod_producto;
        this.nombre_prod = item.nom_producto;
        this.precio = item.precio;
  
  
  
        this.calculoTotal(); // función de cálculo total
        this.llamarCalculo();
        this.dialog_productos = false;
      },
  
      agregarProducto() {
        if (this.validarProducto()) {
          if (this.id_compra) {
            if (this.id_detalle_compra) {
              this.actualizarDetalleCompra();
            } else {
              this.crearDetalleCompra();
            }
          } else {
            this.items_productos.push({
  
              cod_producto: this.cod_prod,
              cantidad_prod: this.cantidad_prod,
              nom_producto: this.nombre_prod,
              precio: parseFloat(this.precio).toFixed(2),
              descuento_servicio: this.descuento_servicio,
              total_prod: parseFloat(this.total_prod).toFixed(2),
            });
  
            this.cod_prod = "";
            this.cantidad_prod = "";
            this.nombre_prod = "";
            this.precio = "";
            this.descuento_servicio = "";
            this.total_prod = "";
  
            // método para calcular el total
            this.calcularTotalInternasImportaciones();
  
            //metodo para calcular el descuento
            this.sumarDescuentos();
  
  
          }
        }
  
      },

      async obtenerDatosEmpresa(id_empresa) {
      try {
        const response = await axios.get(`api/DetallesEmpresas/BusquedaEmpresa/${id_empresa}`);

        const datos = response.data[0];

        this.rubro = datos.rubro;
        


      } catch (error) {
        console.error('Error al obtener los datos de la API', error);
      }
    },
  
      crearCompra() {
        if (this.validarFormulario()) {
          this.loading = true;
          axios
            .post("api/Compras/Crear", {
  
              id_usuario: this.id_usuario,
              id_tipo_doc_fiscal: this.tipo_doc_fiscal.id_tipo_doc_fiscal,
              id_sucursal: this.sucursal,
              id_proveedor: this.id_proveedor,
              id_empresa: this.$store.state.emp.id_empresa,
              fecha: this.fecha,
              condicion_pago: this.cond_pago,
              documento: this.documento,
              comp_retencion: this.comp_retencion,
              con_renta: this.con_renta,
              serie: this.serie,
              resolucion: this.resolucion,
              fecha_recepcion: this.fecha_recepcion,
              emitir_anulado: this.emitir_anulado,
              num_poliza: this.num_poliza,
              quedan: this.quedan,
              internas_importaciones: this.internas_importaciones,
              iva: this.iva,
              renta: parseFloat(this.renta).toFixed(2),
  
              //nuevos
  
              rubro: this.rubro,
  
              s_retencion: this.s_retencion,
              s_percepcion: this.s_percepcion,
  
              selec_manual: this.selec_manual,
              tipo_operacion: this.tipo_operacion,
              clasificacion: this.clasificacion,
              costo_gastos: this.costo_gastos,
  
              no_sujeto: this.no_sujeto,
              internas_importaciones_exentas: this.internas_importaciones_exentas,
              otros_cargos: this.otros_cargos,
              descuento: this.descuento,
              fovial: this.fovial,
              cotrans: this.cotrans,
  
  
  
              total_operacion: this.total_operacion,
              total_pagar: this.total_pagar,
              crearDetalleCompra: this.items_productos,
              estatus: "GUARDADO",
  
  
  
            })
            .then(() => {
              this.loading = false;
              this.incrementarCorrelativoTipoDoc();
              this.listarCompras();
              this.snackbar_text = "Registro creado con éxito";
              this.snackbar = true;
              this.cerrarDialogCompras();
            })
            .catch((error) => {
              console.error("Error al crear la compra:", error);
              this.snackbar_text = "No se pudo crear el registro";
              this.snackbar = true;
              this.loading = false;
            });
        }
      },
  
      actualizarCompra() {
        if (this.validarFormulario()) {
          const dataToSend = {
            id_compra: this.id_compra,
            id_usuario: this.id_usuario,
            id_tipo_doc_fiscal: this.tipo_doc_fiscal.id_tipo_doc_fiscal,
            id_sucursal: this.sucursal,
            id_proveedor: this.id_proveedor,
            id_empresa: this.id_empresa,
            fecha: this.fecha,
            condicion_pago: this.cond_pago,
            documento: this.documento,
            comp_retencion: this.comp_retencion,
            con_renta: this.con_renta,
            serie: this.serie,
            resolucion: this.resolucion,
            fecha_recepcion: this.fecha_recepcion,
            emitir_anulado: this.emitir_anulado,
            num_poliza: this.num_poliza,
            quedan: this.quedan,
            internas_importaciones: this.internas_importaciones,
            iva: this.iva,
            renta: this.renta,
  
            //nuevos
  
            rubro: this.rubro,
  
            s_retencion: this.s_retencion,
            s_percepcion: this.s_percepcion,
  
            selec_manual: this.selec_manual,
            tipo_operacion: this.tipo_operacion,
            clasificacion: this.clasificacion,
            costo_gastos: this.costo_gastos,
  
            no_sujeto: this.no_sujeto,
            internas_importaciones_exentas: this.internas_importaciones_exentas,
            otros_cargos: this.otros_cargos,
            descuento: this.descuento,
            fovial: this.fovial,
            cotrans: this.cotrans,
  
  
            total_operacion: this.total_operacion,
            total_pagar: this.total_pagar,
            estatus: "GUARDADO",
            actualizarDetalleCompra: this.items_productos.map(product => ({
              id_detalle_compra: product.id_detalle_compra,
              id_compra: product.id_compra,
  
              cod_producto: product.cod_producto,
              nom_producto: product.nom_producto,
              cantidad_prod: product.cantidad_prod,
              total_prod: product.total_prod,
              precio: product.precio,
            })),
          };
          this.loading = true;
          axios
            .put("api/Compras/Actualizar", dataToSend)
            .then(() => {
              this.loading = false;
              this.cerrarDialogCompras();
              this.listarCompras();
              this.snackbar_text = "Registro actualizado con éxito";
              this.snackbar = true;
            })
            .catch((error) => {
              console.error("Error al actualizar la compra:", error.response.data);
              this.snackbar_text = "No se pudo actualizar el registro";
              this.snackbar = true;
              this.loading = false;
            });
        }
      },
  
      verificarSucursalSeleccionada() {
        if (!this.sucursal || this.sucursal === 0) {
          this.errorSucursal = true;
          this.snackbar_text = "Debe seleccionar una sucursal válida";
          this.snackbar = true;
          return false;
        }
        this.errorSucursal = false;
        return true;
      },
  
      validarFormulario() {
        let isValid = true;
  
        this.errorFecha = !this.fecha;
        this.errorSucursal = !this.sucursal;
        this.errorDocumentoIdentificador = !this.tipo_doc_fiscal;
        this.errorCodigoG = !this.serie;
        this.errorInternas_Importaciones = !this.internas_importaciones;
        this.errorTotal_Operacion = !this.total_operacion;
        this.errorTotal_Pagar = !this.total_pagar;
        this.errorCondicionPago = !this.cond_pago;
  
  
        if (this.errorFecha || this.errorSucursal || this.errorDocumentoIdentificador || this.errorInternas_Importaciones
          || this.errorTotal_Operacion || this.errorTotal_Pagar || this.errorCodigoG || this.errorCondicionPago) {
          isValid = false;
        }
  
        return isValid;
  
      },
  
      validarProducto() {
        let isValid = true;
  
        this.errorCodigo = !this.cod_prod;
        if (this.errorCodigo) {
          isValid = false;
        }
  
        return isValid;
      },
  
      crearDetalleCompra() {
        axios
          .post("api/DetalleCompras/Crear", {
            id_compra: this.id_compra,
  
            cod_producto: this.cod_prod,
            nom_producto: this.nombre_prod,
            precio: this.precio,
  
            //nuevos
            cantidad_prod: this.cantidad_prod,
            total_prod: this.total_prod,
  
            //nuevo
            descuento_servicio: this.descuento_servicio
          })
          .then(() => {
  
            this.cod_prod = "";
            this.nombre_prod = "";
            this.precio = "";
            this.listarDetallesCompras(this.id_compra);
            this.snackbar_text = "Registro creado con exito";
            this.snackbar = true;
  
            // Metodo para calcular el total
            this.calcularTotalInternasImportaciones();
  
  
          })
          .catch(() => {
            this.snackbar_text = "No se pudo crear el registro";
            this.snackbar = true;
          });
      },
  
      actualizarDetalleCompra() {
        axios
          .put("api/DetalleCompras/Actualizar", {
            id_detalle_compra: this.id_detalle_compra,
            id_compra: this.id_compra,
  
            cod_producto: this.cod_prod,
            nom_producto: this.nombre_prod,
            precio: this.precio,
  
            //nuevos
            cantidad_prod: this.cantidad_prod,
            total_prod: this.total_prod,
          })
          .then(() => {
            this.id_detalle_compra = "";
            this.id_compra = "";
            this.cod_prod = "";
            this.nom_producto = "";
            this.precio = "";
            this.cantidad_prod = "";
            this.total_prod = "";
  
            this.listarDetallesCompras(this.id_compra);
            this.snackbar_text = "Registro actualizado con éxito";
            this.snackbar = true;
            this.dialog_compras = false;
  
            // Metodo para calcular el total
            this.calcularTotalInternasImportaciones();
  
          })
          .catch(() => {
            this.snackbar_text = "No se pudo actualizar el registro";
            this.snackbar = true;
          });
      },
  
      limpiar() {
        this.id_usuario = "";
        this.id_proveedor = "";
        this.tipo_doc_fiscal = "";
        this.id_empresa = 0;
        this.fecha = "";
        this.cond_pago = "";
        this.documento = "DTE-00-00000000-000000000000000";
        this.comp_retencion = "";
        this.con_renta = true;
        this.serie = "";
        this.resolucion = "";
        this.fecha_recepcion = "",
          this.emitir_anulado = false;
        this.num_poliza = "";
        this.quedan = "";
        this.cantidad_prod = 1;
        this.cod_prod = "";
        this.nombre_prod = "";
        this.precio = 0;
        this.descuento_servicio = 0;
        this.total_prod = 0;
        this.internas_importaciones = 0;
        this.iva = 0;
        this.renta = 0;
        this.total_operacion = 0;
        this.total_pagar = 0;
        this.gravadas = 0,
          this.exentas = 0,
          this.otros_cargos = 0,
          this.no_sujeto = 0,
          this.retencion = 0,
          this.percepcion = 0,
          this.fovial = 0,
          this.cotrans = 0,
          this.errorCodigo = false;
        this.errorDocumentoIdentificador = false;
        this.errorSucursal = false;
        this.errorInternas_Importaciones = false;
        this.errorTotal_Operacion = false;
        this.errorTotal_Pagar = false;
        this.errorCodigoG = false;
        this.errorCondicionPago = false;
  
  
        this.costo_gastos = "";
        this.clasificacion = "";
        this.tipo_operacion = "";
      },
  
      editarCompra(item) {
  
        this.id_compra = item.id_compra;
        this.id_usuario = item.id_usuario;
        this.nombre_usuario = this.items_usuario.find(user => user.id_usuario === item.id_usuario)?.nombre || '';
        this.id_proveedor = item.id_proveedor;
        this.id_tipo_doc_fiscal = item.id_tipo_doc_fiscal;
        this.tipo_doc_fiscal = item;
        this.sucursal = item.id_sucursal;
        this.id_empresa = item.id_empresa;
        this.fecha = item.fecha;
        this.cond_pago = item.condicion_pago;
        this.documento = item.documento;
        this.comp_retencion = item.comp_retencion;
        this.con_renta = item.con_renta;
        this.serie = item.serie;
        this.resolucion = item.resolucion;
        this.fecha_recepcion = item.fecha_recepcion;
        this.emitir_anulado = item.emitir_anulado;
        this.num_poliza = item.num_poliza;
        this.quedan = item.quedan;
        this.internas_importaciones = item.internas_importaciones;
        this.iva = item.iva;
        this.renta = item.renta;
        this.total_operacion = item.total_operacion;
        this.total_pagar = item.total_pagar;

        this.rubro = this.items_rubro.find(user => user.rubro === item.rubro)?.rubro || '';
  
        //DETALLES DEL PROVEEDOR
        this.cod_prov = item.cod_proveedor;
        this.nombre_prov = item.nom_proveedor;
        this.receptor_prov = item.receptor;
        this.tamaño_prov = item.tamaño;
        this.registro_prov = item.registro;
        this.con_retencion_prov = item.s_retencion;
        this.con_percepcion_prov = item.s_percepcion;
  
        this.listarDetallesCompras(item.id_compra);
  
        this.flag = 2;
        this.dialog_compras = true;
        this.listarTipoDocs();
      },
  
      editarProducto(item) {
        this.id_compra = item.id_compra;
  
        this.id_detalle_compra = item.id_detalle_compra;
        this.cod_prod = item.cod_producto;
        this.nombre_prod = item.nom_producto;
        this.precio = item.precio;
  
        //nuevos
        this.cantidad_prod = item.cantidad_prod;
        this.total_prod = item.total_prod;
      },
  
      removerProducto(item) {
        if (item.id_detalle_compra > 0) {
          this.id_detalle_compra = item.id_detalle_compra;
          this.dialog_eliminar_producto_detalle = true;
        } else {
          var index = this.items_productos.findIndex(
            (x) => x.id_detalle_compra == item.id_detalle_compra
          );
          this.items_productos.splice(index, 1);
  
          // Metodo para calcular el total
          this.calcularTotalInternasImportaciones();
  
  
        }
      },
  
      eliminarProductoDetalle() {
        axios
          .delete("api/DetalleCompras/Eliminar/" + this.id_detalle_compra)
          .then(() => {
            this.dialog_eliminar_producto_detalle = false;
            this.listarDetallesCompras(this.id_compra);
            this.snackbar_text = "El registro se elimino con exito";
            this.snackbar = true;
          })
          .catch(() => {
            this.snackbar_text = "No se pudo eliminar el registro";
            this.snackbar = true;
          });
      },
  
      //Calculo del IVA
      calculoIva() {
        if (this.tipo_doc_fiscal.cod_doc_fiscal === "CCF") {
  
          this.iva = parseFloat(Number(this.gravadas) * 0.13).toFixed(2);
        } else if (this.tipo_doc_fiscal.cod_doc_fiscal === "FSE") {
          this.iva = 0; // No aplica IVA para FSE
        }
      },
  
      //Calculo Descuento
      calculoDescuento() {
        const cantidad = parseFloat(this.cantidad_prod) || 0;
        const precio = parseFloat(this.precio) || 0;
        const porcentajeDescuento = parseFloat(this.descuento_servicio) || 0;
  
        // Calcula el monto de descuento
        this.monto_descuento = (cantidad * precio * (porcentajeDescuento / 100)).toFixed(2);
  
  
        this.descuento = this.monto_descuento;
  
        this.calcularTotales();
      },
  
  
      sumarDescuentos() {
        if (this.descuento_servicio > 0) {
          this.flag_descuento = true;
        }
        this.descuento = parseFloat(
          Number(this.descuento) + Number(this.monto_descuento)
        ).toFixed(2);
      },
  
      calculoTotal() {
        const cantidad = parseFloat(this.cantidad_prod);
        const precio = parseFloat(this.precio);
  
        this.total_prod = (cantidad * precio).toFixed(2);
        this.calcularTotales();
  
        if (!isNaN(cantidad) && !isNaN(precio)) {
          this.total_prod = (cantidad * precio).toFixed(2); //2 decimales
        } else {
          this.total_prod = 0;
        }
      },
  
      llamarCalculo() {
        for (var i = 0; i < this.items_productos.length; i++) {
          //CALCULOS DE DESCUENTO
          this.sumarDescuentos();
        }
      },
  
  
      enviarDTE(item) {
        //console.log(item);
        this.loading_compras = true;
        axios
          .post("api/ServicioRecepciones/FirmarDocumento2", {
            cod_emp: this.$store.state.emp.id_empresa,
            tipoDte: item.cod_doc_fiscal,
            idFactura: item.id_compra,
            ambiente: "00",
          })
          .then(() => {
            this.loading_compras = false;
            this.snackbar_text = "Solicitud enviada";
            this.snackbar = true;
            this.listarCompras();
          })
          .catch(() => {
            this.loading_compras = false;
            this.snackbar_text = "Error de solicitud";
            this.snackbar = true;
          });
      },
  
      vistaPreviaPdf(item) {
        //console.log(item);
        this.loading_compras = true;
        axios
          .post("api/ServicioRecepciones/VistaPreviaDTE2", {
            cod_emp: this.$store.state.emp.id_empresa,
            tipoDte: item.cod_doc_fiscal,
            idFactura: item.id_compra,
            ambiente: "00",
            es_previo: true,
          })
          .then((response) => {
            //console.log(response.data);
            this.loading_compras = false;
            this.base64_doc = "data:application/pdf;base64," + response.data;
            this.dialog_preview_report = true;
          })
          .catch(() => {
            this.snackbar_text = "No se pudo generar el reporte";
            this.snackbar = true;
            this.loading_compras = false;
          });
      },
  
      vistaPreviaPdfSello(item) {
        this.loading_compras = true;
        axios
          .post("api/ServicioRecepciones/DescargarFacturaWeb", {
            cod_emp: this.$store.state.emp.id_empresa,
            tipoDte: item.cod_doc_fiscal,
            idFactura: item.id_compra,
            ambiente: "00",
            es_previo: false,
          })
          .then((response) => {
            //console.log(response.data);
            this.loading_compras = false;
            this.base64_doc = "data:application/pdf;base64," + response.data;
            this.dialog_preview_report = true;
          })
          .catch(() => {
            this.snackbar_text = "No se pudo generar el reporte";
            this.snackbar = true;
            this.loading_compras = false;
          });
      },
  
      confirmarAnularDocumento() {
        this.dialog_confirmar_anular = true;
      },
  
      anularFactura() {
        axios
          .post("api/ServicioRecepciones/FirmarDocumento2", {
            cod_emp: this.$store.state.emp.id_empresa,
            tipoDte: "INV",
            idFactura: this.id_compra,
            ambiente: "00",
          })
          .then(() => {
            this.snackbar_text = "Solicitud enviada";
            this.snackbar = true;
            this.listarCompras();
          })
          .catch(() => {
            this.snackbar_text = "Error de solicitud";
            this.snackbar = true;
          });
      },
  
      cerrarDialogCompras() {
        this.dialog_compras = false;
  
        this.id_compra = 0;
        this.id_proveedor = 0;
        this.id_tipo_doc_fiscal = 0;
        this.id_sucursal = 0;
        this.id_empresa = 0;
        this.fecha = "";
        this.cond_pago = "";
        this.documento = "";
        this.comp_retencion = "";
        this.con_renta = "";
        this.serie = "";
        this.resolucion = "";
        this.emitir_anulado = false;
        this.num_poliza = "";
        this.quedan = "";
        this.internas_importaciones = "";
        this.iva = 0;
        this.renta = "";
        this.total_operacion = "";
        this.total_pagar = "";
  
        this.items_productos = [];
  
        //DETALLES DEL PROVEEDOR
        this.cod_prov = "";
        this.nombre_prov = "";
        this.receptor_prov = "";
        this.tamaño_prov = "";
        this.registro_prov = "";
        this.con_retencion_prov = "";
        this.con_percepcion_prov = "";
  
  
        this.limpiar();
      },
  
      listarTipoDocs() {
        if (this.sucursal) {
          axios
            .get("api/ConfiFacturas/Listar/" + this.sucursal)
            .then((response) => {
              this.items_tipo_doc_fiscales = response.data;
  
              if (this.items_productos.length > 0) {
  
  
                for (var i = 0; i < this.items_productos.length; i++) {
  
                  //CALCULOS DE EXENTO Y NO SUJETO
                  if (this.cliente_exento) {
                    this.calculoExento(this.items_productos[i].total);
                    this.limpiarCamposProductos();
                    continue;
                  }
  
                  if (this.items_productos[i].exento) {
                    this.calculoExento(this.items_productos[i].total);
                    this.limpiarCamposProductos();
                    continue;
                  }
                  if (this.items_productos[i].no_sujeto) {
                    this.calculoNoSujeto();
                    this.limpiarCamposProductos();
                    continue;
                  }
  
                  if (this.cliente_retencion) {
                    if (this.total >= 100) {
                      this.retencion = (
                        Number(this.total) * Number(0.01)
                      ).toFixed(2);
                    }
                  }
                  if (this.cliente_percepcion) {
                    if (this.total >= 100) {
                      this.percepcion = (
                        Number(this.total) * Number(0.01)
                      ).toFixed(2);
                    }
                  }
  
                  if (
                    this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
                    this.tipo_doc_fiscal.cod_doc_fiscal == "NDB" ||
                    this.tipo_doc_fiscal.cod_doc_fiscal == "NRM"
                  ) {
                    this.calculoIva(this.internas_importaciones);
                    this.llamarCalculo();
                  }
                }
              }
            });
        }
      },
  
      //Calculo de la renta
      // Calculo de retención para renta si aplica
      calculoRetencionRenta() {
        const subTotal = this.tipo_doc_fiscal && this.tipo_doc_fiscal.cod_doc_fiscal === "EX"
          ? parseFloat(this.internas_importaciones_exentas) || 0
          : parseFloat(this.internas_importaciones) || 0;
  
        if (this.tipo_doc_fiscal && this.tipo_doc_fiscal.cod_doc_fiscal === "FSE") {
          if (this.con_renta) {
            this.renta = (subTotal * 0.10).toFixed(2);
          } else {
            this.renta = 0;
          }
        } else if (this.tipo_doc_fiscal && this.tipo_doc_fiscal.cod_doc_fiscal === "CCF" && this.s_retencion && subTotal >= 100) {
          this.retencion = (subTotal * 0.01).toFixed(2);
        } else {
          this.retencion = 0;
        }
  
        // Llamar a calcularTotales para que se actualice el total a pagar
        this.calcularTotales();
      },
  
  
  
      listarDocSeleccionado() {
        var formato = "DTE-";
        var formato_ceros = "-00000000-000000000000000";
        var correlativo_length = "";
        var id = 0;
  
        if (this.tipo_doc_fiscal.id_tipo_doc_fiscal != undefined) {
          id = this.tipo_doc_fiscal.id_tipo_doc_fiscal;
        } else {
          id = this.id_tipo_doc_fiscal;
        }
        //console.log(this.tipo_doc_fiscal)
        axios
          .get(
            "api/ConfiFacturas/ListarSeleccionado/" +
            id +
            "/" +
            this.$store.state.emp.id_empresa
          )
          .then((response) => {
            this.tipo_doc_fiscal = response.data;
  
            correlativo_length = response.data.correlativo.toString().length;
  
            formato_ceros = formato_ceros.substring(
              0,
              formato_ceros.length - correlativo_length
            );
  
            this.documento = formato
              .concat(response.data.cod_mh_doc_fiscal)
              .concat(formato_ceros)
              .concat(response.data.correlativo);
  
            //console.log("listarDocSeleccionado response data:", response.data);
          })
          .catch((error) => {
            console.error("Error en listarDocSeleccionado:", error);
          });
      },
  
  
  
      incrementarCorrelativoTipoDoc() {
        axios
          .put("api/ConfiFacturas/Incrementar", {
            id_confi_factura: this.tipo_doc_fiscal.id_confi_factura,
            id_empresa: this.$store.state.emp.id_empresa,
            valor: Number(this.tipo_doc_fiscal.correlativo) + 1,
          })
          .then(() => {
            this.limpiar();
          })
          .catch((error) => {
            console.error("Error al incrementar el correlativo:", error);
            this.snackbar_text = "No se pudo incrementar el correlativo";
            this.snackbar = true;
          });
      },
  
      formatoFecha(item) {
        return settings.metodos.formatoFechaHora(item);
      },
      async generarGuid() {
        try {
          const response = await axios.get("api/Compras/GenerarGuid");
          this.serie = response.data;
        } catch (error) {
          console.error("Error al generar GUID:", error);
        }
      },
  
      // Calculo internas/importaciones
      calcularTotalInternasImportaciones() {
        // Determina el valor total según si es gravada o exenta
        const total = this.tipo_doc_fiscal && this.tipo_doc_fiscal.cod_doc_fiscal === "EX"
          ? parseFloat(this.internas_importaciones_exentas || 0)
          : parseFloat(this.internas_importaciones || 0);
  
        // Suma todos los productos de acuerdo al tipo
        if (this.tipo_doc_fiscal && this.tipo_doc_fiscal.cod_doc_fiscal === "EX") {
          this.internas_importaciones_exentas = this.items_productos.reduce((total, item) => {
            return total + parseFloat(item.total_prod || 0);
          }, 0).toFixed(2);
        } else {
          this.internas_importaciones = this.items_productos.reduce((total, item) => {
            return total + parseFloat(item.total_prod || 0);
          }, 0).toFixed(2);
        }
  
        this.calcularTotales(); // Recalcula los totales con la nueva información
        this.calculoRetencionRenta(); // Actualiza el cálculo de retención de renta si aplica
      },
  
  
      calcularTotales() {
        // Determina si usa el valor de gravadas o exentas
        const subTotal = this.tipo_doc_fiscal && this.tipo_doc_fiscal.cod_doc_fiscal === "EX"
          ? parseFloat(this.internas_importaciones_exentas) || 0
          : parseFloat(this.internas_importaciones) || 0;
  
        const descuento = parseFloat(this.descuento) || 0;
  
        // Calculo de gravadas descontando el monto de descuento
        this.gravadas = (subTotal - descuento).toFixed(2);
  
        // Aplica IVA solo si el tipo de documento es CCF y la compra es gravada
        if (this.tipo_doc_fiscal && this.tipo_doc_fiscal.cod_doc_fiscal === "CCF" && this.internas_importaciones > 0) {
          this.iva = (this.gravadas * 0.13).toFixed(2);
        } else {
          this.iva = 0;
        }
  
        // Calcular el total de operación: suma de gravadas e IVA
        this.total_operacion = (parseFloat(this.gravadas) + parseFloat(this.iva)).toFixed(2);
  
        const exentas = parseFloat(this.exentas || 0);
        const noSujeto = parseFloat(this.no_sujeto || 0);
        const otrosCargos = parseFloat(this.otros_cargos || 0);
  
        // Calcula el total a pagar considerando gravadas o exentas según el tipo y restando la renta
        this.total_pagar = (
          parseFloat(this.gravadas) +
          parseFloat(this.iva) +
          parseFloat(this.retencion) +
          parseFloat(this.percepcion) +
          parseFloat(this.fovial) +
          parseFloat(this.cotrans) +
          parseFloat(this.importaciones) +
          exentas +
          noSujeto +
          otrosCargos -
          descuento - // Descuento aplicado
          parseFloat(this.renta) // Resta de renta
        ).toFixed(2);
      },
  
  
    },
  };
  </script>