<template>
  <v-app>
    <v-app-bar
      app
      color="#4CC7C4"
      dark
      :clipped-left="$vuetify.breakpoint.mdAndUp"
    >
      <v-app-bar-nav-icon
        v-if="esta_logueado"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div :class="$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'caption'">
        FACTURA ELECTRÓNICA
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="esta_logueado" color="primary" @click="cerrarSesion()"
        >Cerrar sesión</v-btn
      >
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
      v-if="esta_logueado"
      mini-variant-width="110"
    >
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-center">
              {{ $store.state.usuario.nombre }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-center">{{
              $store.state.emp ? $store.state.emp.nombre_empresa : ""
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- INICIO-->
      <v-list dense nav rounded>
        <v-list-item
          v-for="item in item_drawer_inicio"
          :key="item.title"
          :to="item.href"
          active-class="primary--text"
          :disabled="!moduloVisible(item.href)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <!-- ADMINISTRACION-->
      <v-list-group color="green">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="text-center body-2"
              >Administrar</v-list-item-title
            >
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="item in items_drawer_admin"
          :key="item.title"
          :to="item.href"
          dense
          :disabled="!moduloVisible(item.href)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- CATALOGOS-->
      <v-list-group color="green">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="text-center body-2"
              >Cátalogos</v-list-item-title
            >
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="item in items_drawer_catalogos"
          :key="item.title"
          :to="item.href"
          dense
          :disabled="!moduloVisible(item.href)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- REPORTERIA-->
      <v-list-group color="green">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="text-center body-2"
              >Reporteria</v-list-item-title
            >
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="item in items_drawer_reporteria"
          :key="item.title"
          :to="item.href"
          dense
          :disabled="!moduloVisible(item.href)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>
      

      <!-- ITEMS SEPARADOS-->
      <v-list dense nav rounded>
        <v-list-item
          v-for="item in item_drawer_individuales"
          :key="item.title"
          :to="item.href"
          active-class="primary--text"
          :disabled="!moduloVisible(item.href)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    

    <v-footer app padless color="#4CC7C4">
      <v-card flat tile color="transparent">
        <v-card-text>
          <a
            href="https://www.facebook.com/profile.php?id=100066456685999"
            target="_blank"
            class="mx-4"
          >
            <v-icon color="#2121b8" size="25"
              >fa-brands fa-square-facebook</v-icon
            >
          </a>
          <a
            href="https://www.instagram.com/devco.system/"
            target="_blank"
            class="mx-4"
          >
            <v-icon color="#de632e" size="25"
              >fa-brands fa-square-instagram</v-icon
            >
          </a>
          <a href="https://devco.sv/" target="_blank" class="mx-4">
            <v-icon color="#6161ec" size="25">fa-solid fa-globe</v-icon>
          </a>
        </v-card-text>
      </v-card>
      <div class="subtitle-2">
        <v-icon size="20">fa-solid fa-phone</v-icon> (+503) 2254-3300
      </div>
      <v-spacer></v-spacer>
      <v-img
        class="mx-4"
        :src="require('@/assets/logo_devco.png')"
        max-width="35"
        max-height="35"
      ></v-img>
    </v-footer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    drawer: true,
    items_drawer_admin: [
      {
        title: "Empresas",
        icon: "fa-solid fa-handshake",
        href: "empresas",
        show: true,
      },
      {
        title: "Usuarios",
        icon: "fa-solid fa-circle-user",
        href: "usuarios",
        show: true,
      },
      {
        title: "Roles",
        icon: "fa-solid fa-user-gear",
        href: "roles",
        show: true,
      },
      {
        title: "Permisos módulos",
        icon: "fa-solid fa-key",
        href: "permisos_modulos",
        show: true,
      },
    ],
    items_drawer_catalogos: [
      {
        title: "Clientes",
        icon: "fa-solid fa-users",
        href: "clientes",
        show: true,
      },
      {
        title: "Servicios",
        icon: "fa-solid fa-rectangle-list",
        href: "servicios",
        show: true,
      },
      {
        title: "Productos",
        icon: "fa-solid fa-cube",
        href: "productos",
        show: true,
      },
      {
        title: "Sucursales",
        icon: "fa-solid fa-people-group",
        href: "sucursales",
        show: true,
      },
      {
        title: "Vendedores",
        icon: "fa-solid fa-user-tag",
        href: "vendedores",
        show: true,
      },
      {
        title: "Proveedores",
        icon: "fa-solid fa-user-tag",
        href: "proveedores",
        show: true,
      },
      {
        title: "Configuración",
        icon: "fa-solid fa-screwdriver-wrench",
        href: "configuracion",
        show: true,
      },
      {
        title: "Configuración SMTP",
        icon: "fa-solid fa-screwdriver-wrench",
        href: "configuracion_smtp",
        show: true,
      },
    ],
    item_drawer_inicio: [
      { title: "Inicio", icon: "fa-solid fa-house", href: "/", show: true },
    ],
    items_drawer_reporteria: [
      {
        title: "Clientes",
        icon: "fa-solid fa-receipt",
        href: "reporte_clientes",
        show: true,
      },
      {
        title: "Proveedores",
        icon: "fa-solid fa-receipt",
        href: "reporte_proveedores",
        show: true,
      },
      {
        title: "Reporte Ventas y Compra",
        icon: "fa-solid fa-receipt",
        href: "reporte_compras_ventas",
        show: true,
      },
    ],
    item_drawer_individuales: [
      {
        title: "Facturación",
        icon: "fa-solid fa-file-invoice-dollar",
        href: "facturacion",
      },
      {
        title: "Compras",
        icon: "fa-solid fa-file-invoice-dollar",
        href: "compras",
      },
      {
        title: "Libros de IVA",
        icon: "fa-solid fa-book",
        href: "librosiva",
      },
      {
        title: "Resumen Ventas",
        icon: "fa-solid fa-file-invoice-dollar",
        href: "resumen_ventas",
      },
      {
        title: "Exportacion Facturas",
        icon: "fa-solid fa-file-export",
        href: "exportacion_facturas",
      },
      {
        title: "Exportar Facturas CSV",
        icon: "fa-solid fa-file-export",
        href: "exportacion_fac_csv",
      },
      
    ],
    items_permisos: [],
  }),
  computed: {
    esta_logueado() {
      if (this.$store.state.usuario) {
        this.listaEmpresa();
        this.listarModulos();
        this.esVisibleModulo();
        return true;
      }
      return false;
    },
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
  methods: {
    listaEmpresa() {
      let cod_emp = localStorage.getItem("cod_emp");
      if (cod_emp) {
        axios
          .get(
            "api/UsuariosXEmpresas/ListarXcodEmp/" +
              cod_emp +
              "/" +
              this.$store.state.usuario.idUsuario
          )
          .then((response) => {
            this.$store.dispatch("guardarEmpresa", response.data[0]);
          })
          .catch(() => {
            console.log("error");
          });
      }
    },
    listarModulos() {
      axios
        .get(
          "api/PermisosPantallas/Listar/" + this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          //console.log(response.data);
          this.items_permisos = response.data;
        });
    },
    esVisibleModulo() {
      var path = window.location.pathname;

      axios
        .get(
          "api/PermisosPantallas/EsVisibleModulo/" +
            this.$store.state.usuario.idUsuario +
            path
        )
        .then((response) => {
          if (response.data.length == 0) {
            this.$router.push({ name: "bienvenido" }).catch(() => {});
          }
        });
    },
    moduloVisible(item) {
    //console.log(item);
    
      var item_ = this.items_permisos.find(
        (object) => object.nombre_pantalla_identificador == item
      );      

      if (item_) {
        if(item_.visible) {
          return true;
        }
      }
    },
    cerrarSesion() {
      localStorage.removeItem("cod_emp");
      this.$store.dispatch("salir");
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.fa-square-instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>